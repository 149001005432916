import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { APPMESSAGES } from 'src/app/datatypes/generic/messages';
import { KDSAccount } from 'src/app/datatypes/kds/kdsaccount';
import { KDSUser } from 'src/app/datatypes/kds/kdsuser';
import { HttpService } from 'src/app/services/http.service';
import { DialogAccountAddComponent } from '../dialog-account-add/dialog-account-add.component';

@Component({
  selector: 'app-dialog-users-add',
  templateUrl: './dialog-users-add.component.html',
  styleUrls: ['./dialog-users-add.component.css']
})
export class DialogUsersAddComponent implements OnInit {

  newUserForm = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    password: new UntypedFormControl('', [Validators.required]),
    user_type_id: new UntypedFormControl("2" , [Validators.required]),
    id: new UntypedFormControl(0),    
  });

  addUser() {
    let kdsuser : KDSUser = this.newUserForm.value as KDSUser
    kdsuser.user_type_id = +kdsuser.user_type_id;
    console.log(kdsuser);
    this.http.KDSCreateUserAccount(kdsuser).subscribe( (response) => {
      this.dialogRef.close(APPMESSAGES.KDS_ENTRY_ADDED);
    });    
  }
  
  constructor(private http: HttpService, public dialogRef: MatDialogRef<DialogAccountAddComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { account: KDSAccount }) { }

  ngOnInit(): void {
  }

}
