<app-generic-loading *ngIf="showLoading"></app-generic-loading>

<div *ngIf="!showLoading">
    <app-kds-partial-header></app-kds-partial-header>
  
    <div style="padding:10px;">
      <mat-card class="example-card">
        <mat-card-header>
          <mat-card-title>Permissions</mat-card-title>
          <mat-card-subtitle>Admin configures permissions here</mat-card-subtitle>
        </mat-card-header>
        <mat-card-actions>
          <button (click)="addPermission()" mat-mini-fab>+</button>
        </mat-card-actions>
      </mat-card>
  
      <br />

    <div *ngIf="!(this.permissions.length > 0)">
      <h1>No Permissions Yet</h1>
    </div>

    <table style="width:100%;" *ngIf="this.permissions.length > 0" mat-table [dataSource]="permissions" class="mat-elevation-z8">
      
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>      

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef> Actions </th>
        <td mat-cell *matCellDef="let element">          
          <button (click)="editPermission(element)" mat-raised-button color="accent">EDIT PERMISSION</button> |          
          <button (click)="deletePermission(element.id)" mat-raised-button color="warn">DELETE PERMISSION</button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</div>