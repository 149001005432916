<h2 mat-dialog-title>Manage User Accounts</h2>
<mat-dialog-content>
    Accounts This User Has Access To
    <mat-grid-list style="width:400px;" rowHeight="200px" cols="1">
        <mat-grid-tile style="width:400px;">
            <mat-form-field style="width:400px;" appearance="outline">
                <mat-label>Accounts</mat-label>
                <mat-select style="width:400px;" [formControl]="useraccounts" multiple>
                    <mat-form-field style="width:100%;" appearance="fill">
                        <mat-label>Search</mat-label>
                        <input [(ngModel)]="searchTxt" matInput placeholder="search">

                      </mat-form-field>
                    <mat-option *ngFor="let account of accountsData | search : searchTxt" [value]="account.id">{{account.name}}</mat-option>

                  <!-- <mat-option *ngFor="let account of accountsData" [value]="account.id">{{account.name}}</mat-option> -->
                </mat-select>
              </mat-form-field>
        </mat-grid-tile>
    </mat-grid-list>
</mat-dialog-content>
<mat-dialog-actions>
    <button type="submit" (click)="updateperms()" mat-raised-button color="primary">UPDATE PERMISSIONS</button>  
    <button mat-button [mat-dialog-close]="true">Cancel</button>
</mat-dialog-actions>