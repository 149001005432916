import { Component, OnInit, Inject } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { APPMESSAGES } from 'src/app/datatypes/generic/messages';

@Component({
  selector: 'app-dialog-confirm',
  templateUrl: './dialog-confirm.component.html',
  styleUrls: ['./dialog-confirm.component.css']
})
export class DialogConfirmComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DialogConfirmComponent> , @Inject(MAT_DIALOG_DATA) public data: { title: string , message: string }) { }

  confirmed(){
    this.dialogRef.close(APPMESSAGES.CONFIRM_YES);
  }
  cancelled(){
    this.dialogRef.close(APPMESSAGES.CONFIRM_NO);
  }

  ngOnInit(): void {
  }

}
