import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { StorageMap } from '@ngx-pwa/local-storage';
import { Observable } from 'rxjs';
import { KDSAccount } from '../datatypes/kds/kdsaccount';
import { KDSUser } from '../datatypes/kds/kdsuser';
import { KDSStore, KDSState } from '../store/store.kds';
import { resetStores } from "@datorama/akita";
import { KDSPermission } from '../datatypes/kds/kdspermissions';

@Injectable({ providedIn: 'root' })
export class KDSQuery extends Query<KDSState> {

  KDS_USER = "kds_user";
  KDS_ACCOUNTS = "kds_accounts";
  KDS_USER_PERMISSIONS = "kds_user_permissions";
  constructor(protected store: KDSStore, private storage: StorageMap) {
    super(store);
  }

  public setPermissions(permissions: KDSPermission[]) {    
    this.storage.set(this.KDS_USER_PERMISSIONS , permissions).subscribe((r) => { console.log(r);});    
    this.store.update( state => ({
        ...state,
        permissions: permissions
    }));
  };

  public getPermissions(): Observable<KDSPermission[]> {
    //console.log(this.store.getValue().permissions.length);
    if (this.store.getValue().permissions.length > 0){
      return new Observable<KDSPermission[]>( (data) => {
        //console.log(data);
        data.next(this.store.getValue().permissions);
      });      
    } else {
      return this.storage.get(this.KDS_USER_PERMISSIONS) as Observable<KDSPermission[]>;
    }
  };

  public checkPermission(permission: string): Promise<boolean> {    
    return new Promise(resolve => {
      let valid : boolean = false;
      this.getPermissions().subscribe( (perms : Array<KDSPermission>) => {     
        //console.log(perms); 
        if (undefined !== perms) {  
          perms.forEach(pmission => {
            if (pmission.name === permission) {
              valid = true;
            }
          })
        }
        resolve(valid); 
      })
    });    
  };

  public setActiveUser(kdsuser: KDSUser) {
    this.storage.set(this.KDS_USER , kdsuser).subscribe(() => {});
    this.store.update( state => ({
        ...state,
        user: kdsuser
    }));
  }
  public setActiveAccounts(kdsaccounts: KDSAccount[]) {
    this.storage.set(this.KDS_ACCOUNTS , kdsaccounts).subscribe(() => {});
    this.store.update( state => ({
        ...state,
        accounts: kdsaccounts
    }));
  }
  public wipeActiveAccounts() {
    let kdsaccounts = new Array<KDSAccount>();
    this.storage.set(this.KDS_ACCOUNTS , kdsaccounts).subscribe(() => {});
    this.store.update( state => ({
        ...state,
        accounts: kdsaccounts
    }));
  }

  public getActiveUser(): Observable<KDSUser> {
    if (this.store.getValue().user.id > 0){
      return new Observable<KDSUser>( (data) => {
        data.next(this.store.getValue().user);
      });      
    } else {
      return this.storage.get(this.KDS_USER) as Observable<KDSUser>;
    }
  }

  public getUserAccounts(): KDSAccount[] {
    return this.store.getValue().accounts;
  }

  public setUserLogout(){
    resetStores();
    this.storage.clear().subscribe(() => {});
  }

  public getActiveTerminal(): number {
    return this.store.getValue().activeTerminal;
  }
  public setActiveTerminal(id: number): void {
    this.store.update( state => ({
      ...state,
      activeTerminal: id
    }));
  }

}
