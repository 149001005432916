import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {ajax} from 'rxjs/ajax';
import {catchError, map, retry, timeout} from 'rxjs/operators';
import { KDSGenericResponse } from '../datatypes/generic/messages';
import { KDSAccount, KDSUserAccounts, KDSUserAccountsDB } from '../datatypes/kds/kdsaccount';
import { KDSPermission } from '../datatypes/kds/kdspermissions';
import { KDSReportOutput } from '../datatypes/kds/kdsreports';
import { KDSTerminal } from '../datatypes/kds/kdsterminal';
import { KDSTerminalConfig } from '../datatypes/kds/kdsterminalconfig';
import { KDSTerminalMessage } from '../datatypes/kds/kdsterminalmessage';
import {KDSUser} from '../datatypes/kds/kdsuser'
import { KDSUserReports } from '../datatypes/kds/kdsuserreports';
import { KDSUserTypePermission } from '../datatypes/kds/kdsusertypepermissions';
import { KDSUserType } from '../datatypes/kds/kdsusertypes';
const kdsAPI = 'https://service.tabit.info/admin/kds';
const kdsAPI2 = 'https://service.tabit.info/kds';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HttpService {


  
  public ajaxHeader = { 'Content-Type': 'application/json'};
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {        
      if (undefined !== error && error !== null) {                        
              if (error.hasOwnProperty('response')) {
                  if (error.response !== null && error.response.hasOwnProperty('data')) {
                      console.log(error.response.data);
                      //result = error.response;
                  }
              }
          }
          return of(result as T);
      };
  }

  KDSLogin(user: KDSUser): Observable<KDSUser> {
    const url = `${kdsAPI}/login`;
    // crossDomain

    return this.httpClient.post(url , user).pipe(
      map( res => {
          console.log(res);
          let response = res as KDSGenericResponse;
          return response.data;
      }),
      catchError(this.handleError('KDSLogin', []))
    );
  }

  KDSCreateAccount(kdsaccount: KDSAccount): Observable<KDSAccount> {
    const url = `${kdsAPI}/account`;
    return this.httpClient.post(url , kdsaccount).pipe(
      map( res => {
          console.log(res);
          let response = res as KDSGenericResponse;
          return response.data;
      }),
      catchError(this.handleError('KDSCreateAccount', []))
    );
  }
  KDSCreateUserAccount(kdsuser: KDSUser): Observable<KDSUser> {
    const url = `${kdsAPI}/user`;
    return  this.httpClient.post(url , kdsuser).pipe(
      map( res => {
          console.log(res);
          let response = res as KDSGenericResponse;
          return response.data;
      }),
      catchError(this.handleError('KDSCreateUserAccount', []))
    );
  }
  KDSDeleteAccount(accountID: number): Observable<KDSAccount> {
    const url = `${kdsAPI}/account?accountid=` + accountID;
    return  this.httpClient.delete(url).pipe(
      map( res => {
          console.log(res);
          let response = res as KDSGenericResponse;
          return response.data;
      }),
      catchError(this.handleError('KDSDeleteAccount', []))
    );
  }

  KDSCreateTerminal(kdsterminal: KDSTerminal): Observable<KDSTerminal> {
    const url = `${kdsAPI}/terminal`;
    return  this.httpClient.post(url , kdsterminal).pipe(
      map( res => {
          console.log(res);
          let response = res as KDSGenericResponse;
          return response.data;
      }),
      catchError(this.handleError('KDSCreateTerminal', {}))
    );
  }

  KDSDeleteTerminal(terminalID: number): Observable<KDSTerminal> {
    const url = `${kdsAPI}/terminal?terminalid=` + terminalID;
    return  this.httpClient.delete(url).pipe(
      map( res => {
          console.log(res);
          let response = res as KDSGenericResponse;
          return response.data;
      }),
      catchError(this.handleError('KDSDeleteTerminal', {}))
    );
  }

  KDSGetAccounts(user_id: number): Observable<KDSAccount[]> {
    const url = `${kdsAPI}/account?userid=` + user_id;
    return  this.httpClient.get(url).pipe(
      map( res => {
          let response = res as KDSGenericResponse;
          return response.data;
      }),
      retry(3),
      catchError(this.handleError('KDSGetAccounts', []))
    );
  }

  KDSGetReportAccounts(user_id: number): Observable<KDSAccount[]> {
    const url = `${kdsAPI}/report/account?userid=` + user_id;
    return  this.httpClient.get(url).pipe(
      map( res => {
          //console.log(res);
          let response = res as KDSGenericResponse;
          return response.data;
      }),
      retry(3),
      catchError(this.handleError('KDSGetReportAccounts', []))
    );
  }

  KDSGetUserAccounts(user_id: number): Observable<KDSUser[]> {
    const url = `${kdsAPI}/user?userid=` + user_id;
    return  this.httpClient.get(url).pipe(
      map( res => {
          console.log(res);
          let response = res as KDSGenericResponse;
          return response.data;
      }),
      retry(3),
      catchError(this.handleError('KDSGetUserAccounts', []))
    );
  }

  KDSGetTerminals(account_id: any): Observable<KDSTerminal[]> {
    const url = `${kdsAPI}/terminal?accountid=` + account_id;
    return  this.httpClient.get(url).pipe(
      map( res => {
          console.log(res);
          let response = res as KDSGenericResponse;
          return response.data;
      }),
      retry(3),
      catchError(this.handleError('KDSGetTerminals', []))
    );
  }

  KDSCreateTerminalMessage(kdsterminal: KDSTerminalMessage): Observable<any> {
    const url = `${kdsAPI}/terminal/message`;
    return  this.httpClient.post(url , kdsterminal).pipe(
      map( res => {
          console.log(res);
          let response = res as KDSGenericResponse;
          return response.data;
      }),
      catchError(this.handleError('KDSCreateTerminalMessage', {}))
    );
  };

  KDSCreateSystemMessage(kdsterminal: KDSTerminalMessage): Observable<any> {
    const url = `${kdsAPI}/system/message`;
    return  this.httpClient.post(url , kdsterminal).pipe(
      map( res => {
          console.log(res);
          let response = res as KDSGenericResponse;
          return response.data;
      }),
      catchError(this.handleError('KDSCreateSystemMessage', {}))
    );
  };

  KDSCreateSerialTerminalMessage(kdsterminal: KDSTerminalMessage): Observable<any> {
    const url = `${kdsAPI}/serial/message`;
    return  this.httpClient.post(url , kdsterminal).pipe(
      map( res => {
          console.log(res);
          let response = res as KDSGenericResponse;
          return response.data;
      }),
      catchError(this.handleError('KDSCreateSerialTerminalMessage', {}))
    );
  };

  KDSCreateTerminalRequest(kdsterminal: KDSTerminalMessage): Observable<any> {
    const url = `${kdsAPI}/terminal/request`;
    return  this.httpClient.post(url , kdsterminal).pipe(
      map( res => {
          console.log(res);
          let response = res as KDSGenericResponse;
          return response.data;
      }),
      catchError(this.handleError('KDSCreateTerminalRequest', {}))
    );
  };

  KDSCreateTerminalSerialRequest(kdsterminal: KDSTerminalMessage): Observable<any> {
    const url = `${kdsAPI}/serial/request`;
    return  this.httpClient.post(url , kdsterminal).pipe(
      map( res => {
          console.log(res);
          let response = res as KDSGenericResponse;
          return response.data;
      }),
      catchError(this.handleError('KDSCreateTerminalRequest', {}))
    );
  };

  // KDS TERMINAL CONFIG
  KDSGetTerminalConfig(terminal_id: any): Observable<KDSTerminalConfig> {
    const url = `${kdsAPI}/terminal/config?terminal_id=` + terminal_id;
    return  this.httpClient.get(url).pipe(
      map( res => {
          console.log(res);
          let response = res as KDSGenericResponse;
          return response.data;
      }),
      retry(3),
      catchError(this.handleError('KDSGetTerminalConfig', []))
    );
  };
  KDSUpdateTerminalConfig(kdsconfig: KDSTerminalConfig): Observable<KDSTerminalConfig> {
    const url = `${kdsAPI}/terminal/config`;
    return  this.httpClient.post(url , kdsconfig).pipe(
      map( res => {
          console.log(res);
          let response = res as KDSGenericResponse;
          return response.data;
      }),
      catchError(this.handleError('KDSUpdateTerminalConfig', []))
    );
  }
  // =====>

  // ***** KDS User Account Management ***** //
  KDSGETUserAccountsPermission(userid: number): Observable<KDSUserAccountsDB[]> {
    const url = `${kdsAPI}/user/accounts?userid=` + userid;
    return  this.httpClient.get(url).pipe(
      map( res => {
          console.log(res);
          let response = res as KDSGenericResponse;
          return response.data;
      }),
      retry(3),
      catchError(this.handleError('KDSGETUserAccounts', []))
    );
  };
  KDSUPDATEUserAccountsPermission(kdsperm: KDSUserAccounts): Observable<any> {
    const url = `${kdsAPI}/user/accounts`;
    return  this.httpClient.post(url , kdsperm).pipe(
      map( res => {
          console.log(res);
          let response = res as KDSGenericResponse;
          return response.data;
      }),
      catchError(this.handleError('KDSUPDATEUserAccountsPermission', []))
    );
  };
  KDSSendReportRequest(kdsinput: KDSReportOutput): Observable<any> {
    const url = `${kdsAPI}/report`;
    return  this.httpClient.post(url , kdsinput).pipe(
      timeout(120000),
      map( res => {
          //console.log(res);
          let response = res as KDSGenericResponse;
          return response.data;
      }),
      catchError(this.handleError('KDSSendReportRequest', []))
    );
  }

  /* REPORTS */
  KDSGetUserReports(user_id: any): Observable<KDSUserReports[]> {
    const url = `${kdsAPI}/report?userid=` + user_id;
    return  this.httpClient.get(url).pipe(
      map( res => {          
        let response = res as KDSGenericResponse;
        return response.data;
      }),
      retry(3),
      catchError(this.handleError('KDSGetUserReports', []))
    );
  };
  KDSDeleteUserReport(user_id: any , report_id: any): Observable<KDSUserReports[]> {
    const url = `${kdsAPI}/report?userid=` + user_id + '&reportid=' + report_id;
    return  this.httpClient.delete(url).pipe(
      map( res => {          
        let response = res as KDSGenericResponse;
        return response.data;
      }),
      retry(3),
      catchError(this.handleError('KDSDeleteUserReport', []))
    );
  };
  /* END REPORTS */

  /**
   * PERMISSIONS
   */
   

  KDSGETAllPermissions(): Observable<KDSPermission[]> {
    const url = `${kdsAPI2}/permissions`;
    return  this.httpClient.get(url).pipe(
      map( res => {
          console.log(res);
          let response = res as KDSGenericResponse;
          return response.data;
      }),
      retry(3),
      catchError(this.handleError('KDSGETAllPermissions', []))
    );
  };

  KDSGETAllUserTypes(): Observable<KDSUserType[]> {
    const url = `${kdsAPI2}/usertypes`;
    return this.httpClient.get(url).pipe(
      map( res => {          
        let response = res as KDSGenericResponse;
        return response.data;
      }),
      retry(3),
      catchError(this.handleError('KDSGETAllUserTypes', []))
    );
  };

  KDSSavePermission(permission: KDSPermission): Observable<any> {
    const url = `${kdsAPI2}/permissions`;
    return this.httpClient.post(url , permission).pipe(
      timeout(120000),
      map( res => {
          //console.log(res);
          let response = res as KDSGenericResponse;
          return response.data;
      }),
      catchError(this.handleError('KDSSavePermission', []))
    );
  };

  KDSDeletePermission(permissionID: number): Observable<any> {
    const url = `${kdsAPI2}/permissions?id=` + permissionID;
    return this.httpClient.delete(url).pipe(
      map( res => {
          console.log(res);
          let response = res as KDSGenericResponse;
          return response.data;
      }),
      catchError(this.handleError('KDSDeletePermission', {}))
    );
  };

  KDSGetUserTypePermissions(tyepid: any): Observable<KDSPermission[]> {
    const url = `${kdsAPI2}/user/permissions?typeid=` + tyepid;    
    return this.httpClient.get(url).pipe(
      map( res => {   
          //console.log(res);       
          let response = res as KDSGenericResponse;
          return response.data;
      }),
      retry(3),
      catchError(this.handleError('KDSGetUserTypePermissions', []))
    );
  };

  KDSSaveUserTypePermissions(permission: KDSUserTypePermission): Observable<any> {
    const url = `${kdsAPI2}/user/permissions`;
    return this.httpClient.post(url , permission).pipe(
      timeout(120000),
      map( res => {
          //console.log(res);
          let response = res as KDSGenericResponse;
          return response.data;
      }),
      catchError(this.handleError('KDSSaveUserTypePermissions', []))
    );
  };
  KDSDeleteUserTypePermissions(tyepid: number): Observable<any> {
    const url =  `${kdsAPI2}/user/permissions?typeid=` + tyepid;
    return this.httpClient.delete(url).pipe(
      map( res => {
          console.log(res);
          let response = res as KDSGenericResponse;
          return response.data;
      }),
      catchError(this.handleError('KDSDeleteUserTypePermissions', {}))
    );
  };

  KDSGETUsersForUser(userid: any): Observable<KDSUser[]> {
    const url = `${kdsAPI2}/users?userid=` + userid;
    //console.log(url);
    return this.httpClient.get(url).pipe(
      map( res => {   
          //console.log(res);   
          let response = res as KDSGenericResponse;
          return response.data;
      }),
      retry(3),
      catchError(this.handleError('KDSUsersForUser', []))
    );
  };

  KDSSaveUserForUser(kdsuser: KDSUser): Observable<KDSUser> {
    const url = `${kdsAPI2}/users`;
    return this.httpClient.post(url , kdsuser).pipe(
      timeout(120000),
      map( res => {
          //console.log(res);
          let response = res as KDSGenericResponse;
          return response.data;
      }),
      catchError(this.handleError('KDSSaveUserForUser', []))
    );
  };
  KDSDeleteUser(userid: number): Observable<any> {
    const url =  `${kdsAPI2}/users?userid=` + userid;
    return this.httpClient.delete(url).pipe(
      map( res => {
          console.log(res);
          let response = res as KDSGenericResponse;
          return response.data;
      }),
      catchError(this.handleError('KDSDeleteUser', {}))
    );
  };

  constructor(private httpClient: HttpClient) { }
}
