<app-generic-loading *ngIf="showLoading"></app-generic-loading>

<div *ngIf="!showLoading">
  <app-kds-partial-header></app-kds-partial-header>


  <mat-sidenav-container class="example-container">
    <mat-sidenav class="mat-elevation-z8" mode="side" opened>
      <h2>REPORTS</h2>
      <mat-divider></mat-divider>

      <button (click)="switchReport('pdf')" mat-menu-item class="menu-button">
        <mat-icon>home</mat-icon>
        <span>Reports</span>
      </button>
      <button (click)="switchReport('download')" mat-menu-item class="menu-button">
        <mat-icon>cloud_download</mat-icon>
        <span>Download</span>
      </button>
      <button (click)="switchReport('daily')" mat-menu-item class="menu-button">
        <mat-icon>watch_later</mat-icon>
        <span>Daily Reports</span>
      </button>
      <!--<button (click)="switchReport('accountdash')" mat-menu-item class="menu-button">
        <span>Account Dashboard</span>
      </button>-->
      <!--
        <button mat-menu-item class="menu-button">
            <mat-icon>home</mat-icon>
            <span>Dashboard</span>
        </button>

        <button (click)="switchReport('accountdash')" mat-menu-item class="menu-button">            
            <span>Account Dashboard</span>
        </button>

        <button mat-menu-item class="menu-button">            
            <span>Terminal Dashboard</span>
        </button>
        -->
      <mat-divider></mat-divider>


    </mat-sidenav>

    <mat-sidenav-content>
      <div class="content mat-elevation-z8">
        <div *ngIf="this.reportType == 'accountdash'" class="main-div-content">

          <h1>KDS Account Dashboard</h1>

          <form *ngIf="this.accountsData.length > 0">
            <mat-form-field style="min-width:400px;" appearance="fill">
              <mat-label>PLEASE SELECT ACCOUNT</mat-label>
              <mat-select (selectionChange)="AccountDropChanged($event)">
                <mat-option *ngFor="let account of this.accountsData; let i = index" value="{{account.id}}">
                  {{account.name}}</mat-option>
              </mat-select>
            </mat-form-field>
            |
            <mat-form-field style="min-width:400px;" appearance="fill">
              <mat-label>PLEASE SELECT TERMINAL (OPTIONAL)</mat-label>
              <mat-select (selectionChange)="TermianlDropChanged($event)">
                <mat-option *ngFor="let terminal of this.terminalsData; let i = index" value="{{terminal.id}}">
                  {{terminal.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </form>

          <table mat-table [dataSource]="dsAccountDash" class="mat-elevation-z8">

            <!--- Note that these columns can be defined in any order.
                          The actual rendered columns are set as a property on the row definition" -->

            <!-- Position Column -->
            <ng-container matColumnDef="position">
              <th mat-header-cell *matHeaderCellDef> No. </th>
              <td mat-cell *matCellDef="let element"> {{element.position}} </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef> Name </th>
              <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>

            <!-- Weight Column -->
            <ng-container matColumnDef="weight">
              <th mat-header-cell *matHeaderCellDef> Weight </th>
              <td mat-cell *matCellDef="let element"> {{element.weight}} </td>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="symbol">
              <th mat-header-cell *matHeaderCellDef> Symbol </th>
              <td mat-cell *matCellDef="let element"> {{element.symbol}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>

        </div>

        <div *ngIf="this.reportType == 'download'" class="main-div-content">
          <app-kds-partial-user-reports-table (ondelete)="deletereport($event)" (onrefresh)="refreshReports()"
            [reports]="this.userReports"></app-kds-partial-user-reports-table>

          <mat-list role="list">
            <mat-list-item *ngFor="let rep of this.reports; let i = index" role="listitem">
              <a target="_blank" href="https://kdsdailybackup.s3.eu-west-1.amazonaws.com/{{rep}}">Download Report</a>
            </mat-list-item>
          </mat-list>
        </div>

        <div *ngIf="this.reportType == 'pdf'" class="main-div-content">

          <h1>DATE RANGE</h1>
          <form [formGroup]="range">
            <mat-form-field>
              <input matInput formControlName="start" [ngxMatDatetimePicker]="pickerFrom"
                placeholder="From date & time">

              <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>

              <ngx-mat-datetime-picker #pickerFrom></ngx-mat-datetime-picker>
            </mat-form-field>
            ===============
            <mat-form-field>
              <input matInput formControlName="end" [ngxMatDatetimePicker]="pickerTo" placeholder="To date & time"
                [min]="getStart()">

              <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>

              <ngx-mat-datetime-picker #pickerTo></ngx-mat-datetime-picker>
            </mat-form-field>

            <mat-divider></mat-divider>

            <h1>TYPE AND FORMAT</h1>
            <p>please note not all reports are available on excel yet.</p>
            <mat-form-field style="min-width:400px;" appearance="fill">
              <mat-label>PLEASE SELECT TYPE OF REPORT</mat-label>
              <mat-select formControlName="reporttype" (selectionChange)="ReportTypeDropChanged($event)">
                <mat-option *ngFor="let report of this.ListReports; let i = index" value="{{report.value}}">
                  {{report.name}}</mat-option>
              </mat-select>
            </mat-form-field> |
            <mat-form-field style="min-width:400px;" appearance="fill">
              <mat-label>PLEASE SELECT FORMAT</mat-label>
              <mat-select formControlName="reportformat" (selectionChange)="ReportFormatDropChanged($event)">
                <mat-option *ngFor="let format of this.ListReportFormats; let i = index" value="{{format}}">{{format}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-divider></mat-divider>

            <h1>OUTPUT</h1>
            <mat-form-field style="min-width:400px;" appearance="fill">
              <mat-label>PLEASE SELECT REPORT OUTPUT</mat-label>
              <mat-select formControlName="reportoutput" (selectionChange)="ReportOutputDropChanged($event)">
                <mat-option *ngFor="let output of this.ListReportOutouts; let i = index" value="{{output}}">{{output}}
                </mat-option>
              </mat-select>
            </mat-form-field> |
            <mat-form-field *ngIf="this.range.get('reportoutput').value == 'EMAIL'" style="min-width:400px;" appearance="fill">
              <mat-label>Which Email Address</mat-label>
              <input matInput formControlName="email" placeholder="{{localUser.email}}">
            </mat-form-field>
          </form>

          <mat-divider></mat-divider>
          <br />
          <button (click)="onReport()" style="width:200px;" disabled="{{!this.range.valid}}" mat-raised-button color="primary">Run Report</button>

          <!--<button type="button" (click)="onReport('SUMMARY')"  mat-raised-button color="primary">Run Summary Report</button> | 
            <button type="button" (click)="onReport('DETAILED')"  mat-raised-button color="primary">Run Detailed Report</button> | 
            <button type="button" (click)="onReport('DETAILEDITEMS')"  mat-raised-button color="primary">Run Detailed Report With Items</button> | 
            <button type="button" (click)="onReport('WAITERSUMMARY')"  mat-raised-button color="primary">Run Detailed Waiter Report</button>-->          



          <!--
            <mat-form-field appearance="fill">
                <mat-label>Enter a date range</mat-label>
                <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                  <input matStartDate formControlName="start" placeholder="Start date">
                  <input matEndDate formControlName="end" placeholder="End date">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
              
                <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
                <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
            </mat-form-field>
            -->
        </div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>


</div>