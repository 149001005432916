import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { KDSUser } from '../datatypes/kds/kdsuser';
import { KDSQuery } from './kds.query';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private kds: KDSQuery, private router: Router) { }

  checkKDSUser(cb){
    this.kds.getActiveUser().subscribe( (user : KDSUser) => {      
      if (undefined !== user && user.id > 0) {
        cb(user);
      } else {
        this.router.navigateByUrl('/kds/login');
      }
    });
  };  
}
