export class KDSAccount {
    id: number;
    user_id: number;
    name: string;   
    created_at?: string;    
}

export class KDSUserAccounts {
    user_id: number;
    user_accounts: number[];
}

export class KDSUserAccountsDB {
    account_id: number;
    id: number;
    user_id: number;
}