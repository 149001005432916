<!--<div class="container100 valign-wrapper center-align">    
    <div class="row">
        <div class="col s12 m12">
            <h2>PLEASE CHOOSE YOUR APP</h2>
            <p>Please choose the website you want to login to.</p>
        </div>
        <div class="col s6 m6">
          <div class="card brown darken-1">
            <div class="card-content white-text">
              <span class="card-title">YOUR LOYALTY APPLICATION</span>
              <p>Your loyalty app admin website. Please click login or information from below.</p>
            </div>
            <div class="card-action">
                <div class="row">                    
                    <div class="col s6"><a routerLink="/yla/login" class="waves-effect orange darken-4 waves-light btn" href="#">LOGIN</a></div>
                    <div class="col s6"><a class="waves-effect orange darken-4 waves-light btn" href="#">INFORMATION</a></div>
                </div>                            
            </div>
          </div>
        </div>
        <div class="col s6 m6">
          <div class="card brown darken-2">
            <div class="card-content white-text">
              <span class="card-title">KITCHEN DISPLAY SYSTEM</span>
              <p>KDS admin portal. Manage all your KDS screens from here.</p>
            </div>
            <div class="card-action">
                <div class="row">                    
                    <div class="col s6"><a routerLink="/kds/login" class="waves-effect orange darken-4 waves-light btn" href="#">LOGIN</a></div>
                    <div class="col s6"><a class="waves-effect orange darken-4 waves-light btn" href="#">INFORMATION</a></div>
                </div>                            
            </div>
          </div>
        </div>
      </div>
</div>-->

<div class="wrapper">
<mat-toolbar color="primary">
  <span style="margin:auto;">PLEASE CHOOSE YOUR APP</span>  
</mat-toolbar>

<mat-divider></mat-divider>
<mat-grid-list cols="2">
  <mat-grid-tile>
    <mat-card color="primary">
      <mat-card-header>
        <mat-card-title>KDS</mat-card-title>   
        <mat-card-subtitle>Manage all your KDS screens from here.</mat-card-subtitle>     
      </mat-card-header>
      
      <mat-card-actions>
        <button routerLink="/kds/login" color="primary" mat-button>LOGIN</button> | 
        <button mat-button color="primary" [matMenuTriggerFor]="menuv1">DOWNLOAD V1</button> |
        <button mat-button color="primary" [matMenuTriggerFor]="menuv2">DOWNLOAD V2</button> |
        <button (click)="dwservice()" color="primary" mat-button>DWSERVICE</button>
        <mat-menu #menuv1="matMenu">
          <button (click)="downloadArmDeb()" mat-menu-item>Raspberry Pi (ARMV7)</button>
          <button (click)="downloadLinuxDeb()" mat-menu-item>Linux (AMD64)</button>
          <button (click)="downloadWindows()" mat-menu-item>Windows (AMD64)</button>
          <button mat-menu-item>Mac (AMD64)</button>
        </mat-menu>
        <mat-menu #menuv2="matMenu">
          <button (click)="downloadArmDebv2()" mat-menu-item>Raspberry Pi (ARMV7)</button>
          <button (click)="downloadLinuxDebv2()" mat-menu-item>Linux (AMD64)</button>
          <button (click)="downloadWindows64v2()" mat-menu-item>Windows (AMD64)</button>
        </mat-menu>
        <button mat-button>INFO</button>
      </mat-card-actions>
    </mat-card>
  </mat-grid-tile>
  <mat-grid-tile>
    <mat-card color="primary">
      <mat-card-header>        
        <mat-card-title>YLA</mat-card-title>
        <mat-card-subtitle>Your Loyalty App Admin</mat-card-subtitle>
      </mat-card-header>            
      <mat-card-actions>
        <button routerLink="/yla/login" color="primary" mat-button>LOGIN</button> | 
        <button mat-button>INFO</button>
      </mat-card-actions>
    </mat-card>
  </mat-grid-tile>
</mat-grid-list>
</div>