import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { APPMESSAGES } from 'src/app/datatypes/generic/messages';
import { KDSAccount } from 'src/app/datatypes/kds/kdsaccount';
import { KDSUser } from 'src/app/datatypes/kds/kdsuser';
import { AuthService } from 'src/app/services/auth.service';
import { GlobalService } from 'src/app/services/global.service';
import { HttpService } from 'src/app/services/http.service';
import { KDSQuery } from 'src/app/services/kds.query';

@Component({
  selector: 'app-dialog-users-manage',
  templateUrl: './dialog-users-manage.component.html',
  styleUrls: ['./dialog-users-manage.component.css']
})
export class DialogUsersManageComponent implements OnInit {

  newUserForm = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    password: new UntypedFormControl('', [Validators.required]),
    user_type_id: new UntypedFormControl("2" , [Validators.required]),
    user_created_id: new UntypedFormControl(0),
    id: new UntypedFormControl(0),    
  });

  addUser() {
    let kdsuser : KDSUser = this.newUserForm.value as KDSUser
    kdsuser.user_type_id = +kdsuser.user_type_id;
    kdsuser.user_created_id = this.localUser.id;
    console.log(kdsuser);
    this.http.KDSSaveUserForUser(kdsuser).subscribe( (response) => {
      this.dialogRef.close(APPMESSAGES.KDS_ENTRY_ADDED);
    });    
  };
  
  public canAddAdmin = false;
  public canAddResller = false;
  public canAddReport = false;
  public canAddApi = false;
  public localUser;
  
  constructor(
    private auth: AuthService,
    private kds: KDSQuery, 
    private http: HttpService, 
    public dialogRef: MatDialogRef<DialogUsersManageComponent>, 
    public global : GlobalService,
    @Inject(MAT_DIALOG_DATA) public data: { account: KDSAccount }) 
  { }

  checkpermissions() {
    this.kds.checkPermission(this.global.SITE_PERMS.ADD_ADMIN).then( (valid) => {
      this.canAddAdmin = valid;
    });
    this.kds.checkPermission(this.global.SITE_PERMS.ADD_API).then( (valid) => {
      this.canAddApi = valid;
    });
    this.kds.checkPermission(this.global.SITE_PERMS.ADD_REPORT).then( (valid) => {
      this.canAddReport = valid;
    });
    this.kds.checkPermission(this.global.SITE_PERMS.ADD_RESELLER).then( (valid) => {
      this.canAddResller = valid;
    });
  };

  ngOnInit(): void {
    this.auth.checkKDSUser((user : KDSUser) => {
      this.localUser = user;   
      this.checkpermissions();   
    });    
  }

}
