import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { APPMESSAGES } from 'src/app/datatypes/generic/messages';
import { KDSUser } from 'src/app/datatypes/kds/kdsuser';
import { AuthService } from 'src/app/services/auth.service';
import { HttpService } from 'src/app/services/http.service';
import { DialogUserPermissionsManageComponent } from '../dialog-user-permissions-manage/dialog-user-permissions-manage.component';
import { DialogUsersAddComponent } from '../dialog-users-add/dialog-users-add.component';

@Component({
  selector: 'app-kds-users',
  templateUrl: './kds-users.component.html',
  styleUrls: ['./kds-users.component.css']
})
export class KdsUsersComponent implements OnInit {

  public showLoading = true;
  public userAccounts : Array<KDSUser> = new Array<KDSUser>();
  public displayedColumns: string[] = ['email', 'user_type_id', 'actions'];
  public localUser : KDSUser = new KDSUser();

  constructor(private auth: AuthService, public dialog: MatDialog, private http: HttpService) { }

  addNewUser(){
    const dialogRef = this.dialog.open(DialogUsersAddComponent);
    dialogRef.afterClosed().subscribe((data) => { 
      this.loadUsers();
    });
  }

  userType(usertypeid : number){
    if (usertypeid === 1){
      return "ADMIN";
    } else if (usertypeid === 3){
      return "REPORT USER";
    } else {
      return "USER";
    }
  }
  loadUsers(){
    this.http.KDSGetUserAccounts(this.localUser.id).subscribe( accounts => {        
      this.userAccounts = accounts;
    });
  }

  editPermissions(e: KDSUser){    
    const dialogRef = this.dialog.open(DialogUserPermissionsManageComponent , {data : { user : e } });
    dialogRef.afterClosed().subscribe((data) => { 
      
    });
  }

  ngOnInit(): void {
    this.auth.checkKDSUser((user : KDSUser) => {
      this.localUser = user;
      this.showLoading = false;
      this.loadUsers();
    });
  }

}
