import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { KdsLoginComponent } from './kds/kds-login/kds-login.component';
import { LoginComponentComponent } from './login-component/login-component.component';
import { HomeComponentComponent } from './pages/home-component/home-component.component';
import { YlaLoginComponent } from './yla/yla-login/yla-login.component';
import { KdsRegisterComponent } from './kds/kds-register/kds-register.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { KDSStore } from './store/store.kds';
import { KdsHomeComponent } from './kds/kds-home/kds-home.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; 
 
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatCardActions, MatCardModule} from '@angular/material/card';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatDividerModule} from '@angular/material/divider';
import {MatMenuModule} from '@angular/material/menu';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';
import {MatIconModule} from '@angular/material/icon';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {KdsAccountsComponent} from './kds/kds-accounts/kds-accounts.component';
import {MatTableModule} from '@angular/material/table';
import {DialogAccountAddComponent} from './kds/dialog-account-add/dialog-account-add.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {GenericLoadingComponent} from './components/generic-loading/generic-loading.component';
import { KdsTerminalsComponent } from './kds/kds-terminals/kds-terminals.component';
import {MatSelectModule} from '@angular/material/select';
import { DialogTerminalAddComponent } from './kds/dialog-terminal-add/dialog-terminal-add.component';
import { KdsPartialHeaderComponent } from './kds/partials/kds-partial-header/kds-partial-header.component';
import { DialogConfirmComponent } from './components/dialog-confirm/dialog-confirm.component';
import { KdsUsersComponent } from './kds/kds-users/kds-users.component';
import { DialogUsersAddComponent } from './kds/dialog-users-add/dialog-users-add.component';
import { YlaPartialUserHeaderComponent } from './yla/partials/yla-partial-user-header/yla-partial-user-header.component';
import { KdsConfigComponent } from './kds/kds-config/kds-config.component';
import {MatTabsModule} from '@angular/material/tabs';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { DatePipe } from '@angular/common';
import { DialogUserPermissionsManageComponent } from './kds/dialog-user-permissions-manage/dialog-user-permissions-manage.component';
import { KdsReportsComponent } from './kds/kds-reports/kds-reports.component';
import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule
} from '@angular-material-components/datetime-picker'; 
import { KdsPartialUserReportsTableComponent } from './kds/partials/kds-partial-user-reports-table/kds-partial-user-reports-table.component';
import { KdsUserTypesComponent } from './kds/pages/kds-user-types/kds-user-types.component';
import { KdsPermissionsComponent } from './kds/kds-permissions/kds-permissions.component';
import { DialogPermissionAddComponent } from './kds/dialogs/dialog-permission-add/dialog-permission-add.component';
import { DialogUserTypeAddComponent } from './kds/dialogs/dialog-user-type-add/dialog-user-type-add.component';
import { DialogUserTypePermissionAddComponent } from './kds/dialogs/dialog-user-type-permission-add/dialog-user-type-permission-add.component';
import { KdsUserTypePermissionsComponent } from './kds/pages/kds-user-type-permissions/kds-user-type-permissions.component';
import { KdsUsersManageComponent } from './kds/pages/kds-users-manage/kds-users-manage.component';
import { DialogUsersManageComponent } from './kds/dialogs/dialog-users-manage/dialog-users-manage.component';
import { BarChartComponent } from './bar-chart/bar-chart.component';  
import { HttpClientModule } from '@angular/common/http';
import { KdsAdminStatisticsComponent } from './kds/kds-admin-statistics/kds-admin-statistics.component';
import { SearchPipe } from './search.pipe';
@NgModule({ 
  declarations: [
    AppComponent,
    LoginComponentComponent,
    HomeComponentComponent,
    YlaLoginComponent,
    KdsLoginComponent,
    KdsRegisterComponent,
    KdsHomeComponent,
    KdsAccountsComponent,
    DialogAccountAddComponent,
    GenericLoadingComponent,
    KdsTerminalsComponent,
    DialogTerminalAddComponent,
    KdsPartialHeaderComponent,
    DialogConfirmComponent,
    KdsUsersComponent,
    DialogUsersAddComponent,
    YlaPartialUserHeaderComponent,
    KdsConfigComponent,
    DialogUserPermissionsManageComponent,
    KdsReportsComponent,
    KdsPartialUserReportsTableComponent,
    KdsUserTypesComponent,
    KdsPermissionsComponent,
    DialogPermissionAddComponent,
    DialogUserTypeAddComponent,
    DialogUserTypePermissionAddComponent,
    KdsUserTypePermissionsComponent,
    KdsUsersManageComponent,
    DialogUsersManageComponent,
    BarChartComponent,
    KdsAdminStatisticsComponent,
    SearchPipe,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatGridListModule,
    MatCardModule,    
    MatButtonModule,
    MatToolbarModule,
    MatDividerModule,
    MatMenuModule,
    MatTooltipModule,

    MatFormFieldModule,
    MatInputModule,
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    MatRippleModule,
    MatTableModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatTabsModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatAutocompleteModule,

    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,

    HttpClientModule
  ],
  providers: [KDSStore, DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
