<h2 mat-dialog-title>Add New Account</h2>
<mat-dialog-content>
    <p>An account is the same as a physical business location, one user can have many accounts which will be businesses.</p>
    <mat-grid-list rowHeight="200px" cols="1">
        <mat-grid-tile>
          <form [formGroup]="newaccountForm">
            <mat-form-field style="width:400px;" appearance="outline">
              <mat-label>Account Name</mat-label>
              <input matInput formControlName="name" placeholder="Name">
              <mat-error *ngIf="newaccountForm.get('name').invalid">Invalid Name</mat-error>
            </mat-form-field>                        
            <br />                        
          </form>
        </mat-grid-tile>
    </mat-grid-list>
</mat-dialog-content>
<mat-dialog-actions>
    <button type="submit" (click)="addAccount()" [disabled]="!newaccountForm.valid" mat-raised-button color="primary">SAVE ACCOUNT</button>  
    <button mat-button [mat-dialog-close]="true">Cancel</button>
</mat-dialog-actions>
