import { Component, OnInit } from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators, ReactiveFormsModule} from '@angular/forms';

@Component({
  selector: 'app-kds-register',
  templateUrl: './kds-register.component.html',
  styleUrls: ['./kds-register.component.css']
})
export class KdsRegisterComponent implements OnInit { 

  registerForm = new UntypedFormGroup({    
    password: new UntypedFormControl('', Validators.required),
    email: new UntypedFormControl('', [Validators.required, Validators.email]),    
  });

  doRegister() {
    alert('abc');
  }
  constructor() { }

  ngOnInit(): void {
  }

}
