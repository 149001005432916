import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { KDSQuery } from 'src/app/services/kds.query';
import { HttpService } from 'src/app/services/http.service';
import { KDSAccount } from 'src/app/datatypes/kds/kdsaccount';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { APPMESSAGES } from 'src/app/datatypes/generic/messages';
import { KDSPermission } from 'src/app/datatypes/kds/kdspermissions';

@Component({
  selector: 'app-dialog-permission-add',
  templateUrl: './dialog-permission-add.component.html',
  styleUrls: ['./dialog-permission-add.component.css']
})
export class DialogPermissionAddComponent implements OnInit {

  newpermissionForm = new UntypedFormGroup({
    name: new UntypedFormControl('', Validators.required),    
    id: new UntypedFormControl(0),    
  });

  constructor(private kds: KDSQuery, private router: Router, private http: HttpService, public dialogRef: MatDialogRef<DialogPermissionAddComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { permission: KDSPermission }) { }

  savePermission() {
    let perm : KDSPermission = this.newpermissionForm.value as KDSPermission;
    console.log(perm);    
    this.http.KDSSavePermission(perm).subscribe((httpdata) => {
      this.dialogRef.close(APPMESSAGES.KDS_ACCOUNT_ADDED);
    });
  }

  ngOnInit(): void {
    this.kds.getActiveUser().subscribe((user) => {
      if (undefined !== user && user.id > 0) {
        this.kds.setActiveUser(user);
        //this.newpermissionForm.get('user_id').setValue(user.id);
        if (this.data !== null) {
          this.newpermissionForm.patchValue(this.data.permission);
        }
      } else {
        this.router.navigateByUrl('/kds/login');
      }
    });
  }
}