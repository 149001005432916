<h2 mat-dialog-title>Manage Permission</h2>
<mat-dialog-content>
    <p>this is user permissions for use in the website , if you are here and you dont know why... you should not be here.</p>
    <mat-grid-list rowHeight="200px" cols="1">
        <mat-grid-tile>
          <form [formGroup]="newpermissionForm">
            <mat-form-field style="width:400px;" appearance="outline">
              <mat-label>Account Name</mat-label>
              <input matInput formControlName="name" placeholder="Name">
              <mat-error *ngIf="newpermissionForm.get('name').invalid">Invalid Name</mat-error>
            </mat-form-field>                        
            <br />                        
          </form>
        </mat-grid-tile>
    </mat-grid-list>
</mat-dialog-content>
<mat-dialog-actions>
    <button type="submit" (click)="savePermission()" [disabled]="!newpermissionForm.valid" mat-raised-button color="primary">SAVE PERMISSION</button>  
    <button mat-button [mat-dialog-close]="true">Cancel</button>
</mat-dialog-actions>