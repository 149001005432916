import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dialog-user-type-add',
  templateUrl: './dialog-user-type-add.component.html',
  styleUrls: ['./dialog-user-type-add.component.css']
})
export class DialogUserTypeAddComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
