import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-kds-admin-statistics',
  templateUrl: './kds-admin-statistics.component.html',
  styleUrls: ['./kds-admin-statistics.component.css']
})
export class KdsAdminStatisticsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
