import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { KDSConfig } from 'src/app/datatypes/kds/kdsconfig';
import { KDSTerminalConfig } from 'src/app/datatypes/kds/kdsterminalconfig';
import { KDSTerminalMessage } from 'src/app/datatypes/kds/kdsterminalmessage';
import { HttpService } from 'src/app/services/http.service';
import { KDSQuery } from 'src/app/services/kds.query';

@Component({
  selector: 'app-kds-config',
  templateUrl: './kds-config.component.html',
  styleUrls: ['./kds-config.component.css']
})
export class KdsConfigComponent implements OnInit {

  public showLoading = true;
  public kdsConfig: KDSConfig;
  public kdsTerminalConfig: KDSTerminalConfig;
  public kdsStatus = "UNKNOWN";
  public kdsHWID = "0";

  public KDSSetupForm = new UntypedFormGroup({    
    name: new UntypedFormControl('', Validators.required),
    screen: new UntypedFormControl('', Validators.required),
    terminal: new UntypedFormControl(''),
    serial: new UntypedFormControl(''),
    css: new UntypedFormControl('wimpy.css'),        
  });

  public KDSFlagsForm = new UntypedFormGroup({    
    compressed: new UntypedFormControl(false),
    showheadings: new UntypedFormControl(false),
    leftalign: new UntypedFormControl(false),
    checkboxes: new UntypedFormControl(false),
    sortsubitems: new UntypedFormControl(false),
    primaryfocus: new UntypedFormControl(false),
  });

  public KDSTimers = new UntypedFormGroup({    
    late1time: new UntypedFormControl(2),
    late2time: new UntypedFormControl(4),
  });

  public KDSColors = new UntypedFormGroup({    
    mainitem: new UntypedFormControl("#FFFF00"),
    subitem: new UntypedFormControl("#FFFFFF"),
    late1: new UntypedFormControl("#FFFF00"),
    late2: new UntypedFormControl("#FF0000"),
    late1time: new UntypedFormControl(2),
    late2time: new UntypedFormControl(4),
    reversecount: new UntypedFormControl(false),
    reversecounttime: new UntypedFormControl(24),
    waitercolor: new UntypedFormControl("#FFFFFF"),
    coursecolor: new UntypedFormControl("#FFFFFF"),
  });

  public KDSTwilioForm = new UntypedFormGroup({    
    twilio_accountsid: new UntypedFormControl(''),
    twilio_authtoken: new UntypedFormControl(''),
    twilio_sendingnumber: new UntypedFormControl(''),
    twilio_message: new UntypedFormControl(''),    
    enablesms: new UntypedFormControl(false),
    prefix: new UntypedFormControl(''),
  });

  public KDSCompleteForm = new UntypedFormGroup({    
    enablesendprinter: new UntypedFormControl(false),
    sendprinterip: new UntypedFormControl(''),    
  });

  public KDSText = new UntypedFormGroup({    
    consumer: new UntypedFormControl('Consumer'),       
  });

  public KDSDuplicate = new UntypedFormGroup({  
    enableduplication: new UntypedFormControl(false),  
    screen1: new UntypedFormControl(''),
    screen2: new UntypedFormControl(''),
    screen3: new UntypedFormControl(''), 
    msgserveroverride: new UntypedFormControl(''),
    remote: new UntypedFormControl('') 
  });

  public KDSFeatures = new UntypedFormGroup({  
    prioritykeywordenabled: new UntypedFormControl(false),  
    prioritykeywordtext: new UntypedFormControl(''),    
  });

  public range = new UntypedFormGroup({
    start: new UntypedFormControl(),
    end: new UntypedFormControl()
  });
  id: number;
  private sub: any;
  
  constructor(private http: HttpService, private kds: KDSQuery, private router: Router, private datepipe: DatePipe, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      this.id = +params['id']; 
      if (this.id > 0) {
        this.kds.setActiveTerminal(this.id);
        if (this.kds.getActiveTerminal() > 0){
          this.http.KDSGetTerminalConfig(this.kds.getActiveTerminal()).subscribe( result => {
            console.log(result);
            this.kdsTerminalConfig = result;
            this.kdsConfig = (JSON.parse(result.config) as KDSConfig);
            this.KDSSetupForm.patchValue(this.kdsConfig.client);
            this.KDSFlagsForm.patchValue(this.kdsConfig.flags);
            this.KDSColors.patchValue(this.kdsConfig.colors);

            console.log(this.KDSColors.value);
            if (this.kdsConfig.hasOwnProperty("smsconfig")) {
              this.KDSTwilioForm.get("enablesms").setValue(this.kdsConfig.smsconfig.enabled);
              if (this.kdsConfig.smsconfig.hasOwnProperty("twilio")) {
                this.KDSTwilioForm.patchValue(this.kdsConfig.smsconfig.twilio);
              }                    
            }
            if (this.kdsConfig.hasOwnProperty("oncomplete")) {
              this.KDSCompleteForm.patchValue(this.kdsConfig.oncomplete);                  
            }
            if (this.kdsConfig.hasOwnProperty("duplicate")) {
              this.KDSDuplicate.patchValue(this.kdsConfig.duplicate);                  
            }
            if (this.kdsConfig.hasOwnProperty("kdsfeatures")) {
              this.KDSFeatures.patchValue(this.kdsConfig.kdsfeatures);                  
            }
            this.showLoading = false;
          });
        } else {
          this.router.navigateByUrl('/kds/terminals');
        }
      }          
   });  
        
  };

  isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  onSyncConfig() : void {
    let mes = new KDSTerminalMessage();
    mes.message = "SYNCCONFIG";
    mes.terminal_id = this.kds.getActiveTerminal();
    mes.type = "SYNCCONFIG";
    this.http.KDSCreateTerminalRequest(mes).subscribe( done => {
      console.log(done);      
    })
  }

  onCheckStatus():void {
    let mes = new KDSTerminalMessage();
      mes.message = "TERMINALSTATUS";
      mes.terminal_id = this.kds.getActiveTerminal();
      mes.type = "TERMINALSTATUS";
      this.http.KDSCreateTerminalRequest(mes).subscribe( done => {
        console.log(done);
        if (!this.isEmpty(done)){
          this.kdsStatus = "ONLINE";
        } else {
          this.kdsStatus = "OFFLINE";
        }
      })
  };

  onGetHWID():void {
    let mes = new KDSTerminalMessage();
    mes.message = "gethwid";
    mes.terminal_id = this.kds.getActiveTerminal();
    mes.type = "GETHWID";
    this.http.KDSCreateTerminalRequest(mes).subscribe( done => {
      let abc = JSON.parse(done);
      this.kdsHWID = abc.message;
    });
  };

  onRangeImport():void {
    console.log(this.range);
    let startDate = this.datepipe.transform(this.range.get('start').value, 'yyyy-MM-dd 00:00:00');
    let endtDate = this.datepipe.transform(this.range.get('end').value, 'yyyy-MM-dd 23:59:59');
    let mes = new KDSTerminalMessage();
    let packet = {
      "start" : startDate,
      "end" : endtDate
    };
    mes.message = JSON.stringify(packet);
    mes.terminal_id = this.kds.getActiveTerminal();
    mes.type = "SEND_DATA_RANGE";
    this.http.KDSCreateTerminalSerialRequest(mes).subscribe( done => {
      console.log(done);
    })
  }

  onUpdateService():void {
      let mes = new KDSTerminalMessage();
      mes.message = "kdstcpserver";
      mes.terminal_id = this.kds.getActiveTerminal();
      mes.type = "UPDATESERVICE";
      this.http.KDSCreateTerminalMessage(mes).subscribe( done => {
        console.log(done);        
      });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  onSave(): void {
    this.kdsConfig.client = this.KDSSetupForm.value;
    this.kdsConfig.client.screen = this.kdsConfig.client.screen.toLowerCase();
    this.kdsConfig.client.terminal = this.kdsConfig.client.screen;

    if (!this.kdsConfig.hasOwnProperty("smsconfig")) {
      this.kdsConfig.smsconfig = {
        enabled: false,
        twilio: {
          twilio_accountsid: '',
          twilio_authtoken: '',
          twilio_message: '',
          twilio_sendingnumber: ''
        }
      }
    }

    if (!this.kdsConfig.hasOwnProperty("duplicate")) {
      this.kdsConfig.duplicate = {
        enableduplication: false,
        screen1: '',
        screen2: '',
        screen3: '',
        msgserveroverride: '',
      }
    }

    if (!this.kdsConfig.smsconfig.hasOwnProperty("twilio")) {
      this.kdsConfig.smsconfig.twilio = {        
          twilio_accountsid: '',
          twilio_authtoken: '',
          twilio_message: '',
          twilio_sendingnumber: ''        
      }
    }

    if (!this.kdsConfig.hasOwnProperty("oncomplete")) {
      this.kdsConfig.oncomplete = {        
        enablesendprinter: false,
        sendprinterip: '',               
      }
    }

    if (!this.kdsConfig.hasOwnProperty("kdsfeatures")) {
      this.kdsConfig.kdsfeatures = {        
        prioritykeywordenabled: false,
        prioritykeywordtext: '',
        removalkeywordenabled: false,
        removalkeyword1: '',
        removalkeyword2: '',
        removalkeyword3: '',
      }
    }

    this.kdsConfig.smsconfig.twilio = this.KDSTwilioForm.value;
    this.kdsConfig.smsconfig.enabled = this.KDSTwilioForm.get("enablesms").value;    
    this.kdsConfig.flags = this.KDSFlagsForm.value;
    this.kdsConfig.colors = this.KDSColors.value;
    this.kdsConfig.oncomplete = this.KDSCompleteForm.value;
    this.kdsConfig.duplicate = this.KDSDuplicate.value;
    this.kdsConfig.kdsfeatures = this.KDSFeatures.value;

    console.log(this.kdsConfig);
    this.kdsTerminalConfig.config = JSON.stringify(this.kdsConfig);
    this.http.KDSUpdateTerminalConfig(this.kdsTerminalConfig).subscribe( result =>  {
      console.log(result);
    });
  }

}
