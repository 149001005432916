import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/services/http.service';
import { KDSQuery } from 'src/app/services/kds.query';
import {MatFormFieldModule} from '@angular/material/form-field';
import { DialogTerminalAddComponent } from '../dialog-terminal-add/dialog-terminal-add.component';
import { APPMESSAGES } from 'src/app/datatypes/generic/messages';
import { KDSTerminal } from 'src/app/datatypes/kds/kdsterminal';
import { DialogConfirmComponent } from 'src/app/components/dialog-confirm/dialog-confirm.component';
import { KDSTerminalMessage } from 'src/app/datatypes/kds/kdsterminalmessage';
import { KDSPermission } from 'src/app/datatypes/kds/kdspermissions';
import { DialogPermissionAddComponent } from '../dialogs/dialog-permission-add/dialog-permission-add.component';

@Component({
  selector: 'app-kds-permissions',
  templateUrl: './kds-permissions.component.html',
  styleUrls: ['./kds-permissions.component.css']
})
export class KdsPermissionsComponent implements OnInit {

  public permissions = Array<KDSPermission>();
  public showLoading = true;
  public accountsData = [];
  public terminalsData = [];
  public activeAccount = null;
  public displayedColumns: string[] = ['name', 'actions'];

  constructor(private kds: KDSQuery, private router: Router, private http: HttpService, public dialog: MatDialog) { }

  editPermission(element){    
    const dialogRef = this.dialog.open(DialogPermissionAddComponent , { data : {
      permission : element
    }});
    dialogRef.afterClosed().subscribe((data) => {      
      if (data === APPMESSAGES.KDS_ACCOUNT_ADDED){        
        this.showLoading = true;        
        this.loadPermissions();
      }
    });
  };

  addPermission(){
    const dialogRef = this.dialog.open(DialogPermissionAddComponent , { data : {
      permission : new KDSPermission()
    }});
    dialogRef.afterClosed().subscribe((data) => { 
      if (data === APPMESSAGES.KDS_ACCOUNT_ADDED){
        this.showLoading = true;        
        this.loadPermissions();
      }
    });
  }

  deletePermission(id){    
    const dialogRef = this.dialog.open(DialogConfirmComponent , {
      data : { title: 'CONFIRM PERMISSION DELETE' , message: 'Do you want to delete this permission ?' }
    });
    dialogRef.afterClosed().subscribe((data) => { 
      if (data === APPMESSAGES.CONFIRM_YES) {
        this.http.KDSDeletePermission(id as number).subscribe(resp => {
          console.log(resp);
          this.showLoading = true; 
          this.loadPermissions();
        });
      }      
    });
  }
          
  loadAccounts(){
    this.kds.getActiveUser().subscribe( (user) => {
      if (undefined !== user && user.id > 0) {
        this.kds.setActiveUser(user);  
        if (this.kds.getUserAccounts().length < 1) {
          this.http.KDSGetAccounts(user.id).subscribe( (httpdata) => {            
            this.accountsData = httpdata;
            this.showLoading = false;
            this.kds.setActiveAccounts(this.accountsData); 
          });      
        } else {
          this.accountsData = this.kds.getUserAccounts();
          this.showLoading = false;
        }
      } else {
        this.router.navigateByUrl('/kds/login');
      }      
    });
  }  
    
  loadPermissions() {
    this.http.KDSGETAllPermissions().subscribe( (data) => {
      this.permissions = data;
      console.log(data);
      this.showLoading = false;
    });
  }

  ngOnInit(): void {
    this.loadAccounts();
    this.loadPermissions();
  }

}
