export class APPMESSAGES {
    static KDS_ACCOUNT_ADDED = 'kds_account_added';
    static KDS_ENTRY_ADDED = 'kds_added';
    static KDS_LOGOUT = 'kds_logout';
    static CONFIRM_YES = 'confirm_yes';
    static CONFIRM_NO = 'confirm_no';
}

export class KDSGenericResponse {    
    data : any    
}