<app-generic-loading *ngIf="showLoading"></app-generic-loading>

<div *ngIf="!showLoading">
  <app-kds-partial-header></app-kds-partial-header>

  <div style="padding:10px;">
    <mat-card class="example-card">
      <mat-card-header>
        <mat-card-title>Terminals</mat-card-title>
        <mat-card-subtitle>Terminal admin (terminals are actual KDS screens linked to an account)</mat-card-subtitle>
      </mat-card-header>
      <mat-card-actions>
        <button (click)="addTerminal()" mat-mini-fab>+</button>
      </mat-card-actions>
    </mat-card>

    <br />

    <div *ngIf="!(this.accountsData.length > 0)">
      <h1>No Accounts Yet</h1>
    </div>

    <!--
    <form *ngIf="this.accountsData.length > 0">
      <mat-form-field style="min-width:400px;" appearance="fill">
        <mat-label>PLEASE SELECT ACCOUNT</mat-label>
        <mat-select (selectionChange)="changed($event)" >
          <mat-option *ngFor="let account of this.accountsData; let i = index" value="{{account.id}}">{{account.name}}</mat-option>          
        </mat-select>
      </mat-form-field>
    </form>
  -->

    <form class="example-form" *ngIf="this.filteredaccountsData.length > 0">
      <mat-form-field style="min-width:500px;" appearance="fill">
        <mat-label>PLEASE FILTER ACCOUNT - AUTOCOMPLETE</mat-label>
        <input type="text" matInput [formControl]="filterAccountControl" [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option *ngFor="let account of this.filteredaccountsData" [value]="account">
            {{account.name}}
          </mat-option>
        </mat-autocomplete>        
      </mat-form-field>
      | 
      <button mat-raised-button color="accent">CLEAR FILTER</button>
    </form>

    <br />

    <table *ngIf="this.terminalsData.length > 0" mat-table [dataSource]="terminalsData" class="mat-elevation-z8">      

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>

      <ng-container matColumnDef="connection_code">
        <th mat-header-cell *matHeaderCellDef> Connection Code </th>
        <td mat-cell *matCellDef="let element"> {{element.connection_code}} </td>
      </ng-container>

      <ng-container matColumnDef="hwid">
        <th mat-header-cell *matHeaderCellDef> In Use </th>
        <td mat-cell *matCellDef="let element"> {{ inUse(element) }} </td>
      </ng-container>

      <!-- Weight Column -->
      <ng-container matColumnDef="created_at">
        <th mat-header-cell *matHeaderCellDef> Created At </th>
        <td mat-cell *matCellDef="let element"> {{element.created_at}} </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef> Actions </th>
        <td mat-cell *matCellDef="let element">       
          <button (click)="editTerminal(element)" mat-raised-button color="accent">EDIT</button> |    
          <button (click)="deleteTerminal(element.id)" mat-raised-button color="warn">DELETE</button> |
          <!-- <button (click)="sendMessage(element)" mat-raised-button color="warn">SEND TEST</button> | -->
          <button (click)="editConfig(element)" mat-raised-button color="accent">CONFIG</button>   
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</div>
