import { Component, OnInit, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/services/http.service';
import { KDSQuery } from 'src/app/services/kds.query';

@Component({
  selector: 'app-kds-home',
  templateUrl: './kds-home.component.html',
  styleUrls: ['./kds-home.component.css']
})
export class KdsHomeComponent implements OnInit {
  
  constructor(private http: HttpService, private kds: KDSQuery, private router: Router) { }

  ngOnInit(): void {        
    this.kds.getActiveUser().subscribe( (user) => {
      if (user.id > 0){
        this.kds.setActiveUser(user);        
      } else {
        this.router.navigateByUrl('/kds/login');
      }      
    });
  }

}
