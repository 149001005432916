import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-yla-partial-user-header',
  templateUrl: './yla-partial-user-header.component.html',
  styleUrls: ['./yla-partial-user-header.component.css']
})
export class YlaPartialUserHeaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
