import { Injectable } from '@angular/core';
import { KDSTopLevelPermission } from '../datatypes/kds/generic';
import { KDSPermission } from '../datatypes/kds/kdspermissions';
import { AuthService } from './auth.service';
import { HttpService } from './http.service';
import { KDSQuery } from './kds.query';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

    public USER_TYPES = {
        ADMIN : 1
    };

    public SITE_PERMS = {
      USER_MENU : "user_screen",
      ADD_ADMIN : "admin_user_add",
      ADD_RESELLER : "reseller_user_add" , 
      ADD_REPORT : "report_user_add" ,
      ADD_API : "api_user_add" ,
    };
    
    constructor(private kds: KDSQuery, private http: HttpService, private auth: AuthService) { }
    private hasRun = false;

    initUserPermissions(cb) {
      if (!this.hasRun) {
        this.auth.checkKDSUser( (user) => {
          console.log(user);
          if (user.id > 0) {
            this.http.KDSGetUserTypePermissions(user.user_type_id).subscribe( (resp : KDSPermission[]) => {              
              this.kds.setPermissions(resp);              
              this.hasRun = true;
              cb();
            });
          }
        });      
      }
    }

    getKDSUserPermissions() : Promise<KDSTopLevelPermission> {
      return new Promise(resolve => {
        this.initUserPermissions( () => {
          let perm = new KDSTopLevelPermission();
          this.kds.checkPermission(this.SITE_PERMS.USER_MENU).then( (valid) => {
            perm.showUsers = valid;
          });
          resolve(perm); 
        });
      });      
    };
}
