import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DialogConfirmComponent } from 'src/app/components/dialog-confirm/dialog-confirm.component';
import { APPMESSAGES } from 'src/app/datatypes/generic/messages';
import { HttpService } from 'src/app/services/http.service';
import { KDSQuery } from 'src/app/services/kds.query';
import { DialogAccountAddComponent } from '../dialog-account-add/dialog-account-add.component';
import * as moment from 'moment';
import { KDSAccount } from 'src/app/datatypes/kds/kdsaccount';

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}
const ELEMENT_DATA: PeriodicElement[] = [
  {position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H'},
  {position: 2, name: 'Helium', weight: 4.0026, symbol: 'He'},
  {position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li'},
  {position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be'},
  {position: 5, name: 'Boron', weight: 10.811, symbol: 'B'},
  {position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C'},
  {position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N'},
  {position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O'},
  {position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F'},
  {position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne'},
];

@Component({
  selector: 'app-kds-accounts',
  templateUrl: './kds-accounts.component.html',
  styleUrls: ['./kds-accounts.component.css']
})
export class KdsAccountsComponent implements OnInit {      

  public displayedColumns: string[] = ['name', 'actions'];
  public datasource : KDSAccount[];
  public accountsData = [];
  public showLoading = true;  

  constructor(private kds: KDSQuery, private router: Router, private http: HttpService, public dialog: MatDialog) {}

  addAccount(){
    const dialogRef = this.dialog.open(DialogAccountAddComponent);
    dialogRef.afterClosed().subscribe((data) => { 
      if (data === APPMESSAGES.KDS_ACCOUNT_ADDED){
        this.showLoading = true;
        this.kds.wipeActiveAccounts();
        this.loadAccounts();
      }
    });
  }
  formatDate(created){
    return moment(created).format('MMMM Do YYYY, h:mm:ss a');
  }
  editAccount(element){    
    const dialogRef = this.dialog.open(DialogAccountAddComponent , { data : {
      account : element
    }});
    dialogRef.afterClosed().subscribe((data) => {      
      if (data === APPMESSAGES.KDS_ACCOUNT_ADDED){        
        this.showLoading = true;
        this.kds.wipeActiveAccounts();
        this.loadAccounts();
      }
    });
  }

  deleteAccount(id){    
    const dialogRef = this.dialog.open(DialogConfirmComponent , {
      data : { title: 'CONFIRM ACCOUNT DELETE' , message: 'Do you want to delete this account ?' }
    });
    dialogRef.afterClosed().subscribe((data) => { 
      if (data === APPMESSAGES.CONFIRM_YES) {
        this.http.KDSDeleteAccount(id as number).subscribe(resp => {
          console.log(resp);
          this.loadAccounts();
        });
      }      
    });
  }
  
  loadAccounts(){
    this.kds.getActiveUser().subscribe( (user) => {
      if (undefined !== user && user.id > 0){
        this.kds.setActiveUser(user);  
        if (this.kds.getUserAccounts().length < 1) {
          this.http.KDSGetAccounts(user.id).subscribe( (httpdata) => {            
            this.accountsData = httpdata;
            this.datasource = httpdata;
            this.showLoading = false;
          });      
        } else {
          this.accountsData = this.kds.getUserAccounts();
          this.datasource = this.accountsData;
          this.showLoading = false;
        }
      } else {
        this.router.navigateByUrl('/kds/login');
      }      
    });
  }
  
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;    
    this.datasource = this.accountsData.filter( option => option.name.toLowerCase().includes(filterValue) );
  }

  ngOnInit(): void {      
      this.loadAccounts();
  }

}
