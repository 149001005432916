import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-yla-login',
  templateUrl: './yla-login.component.html',
  styleUrls: ['./yla-login.component.css']
})
export class YlaLoginComponent implements OnInit {

  loginForm = new UntypedFormGroup({    
    password: new UntypedFormControl('', Validators.required),
    email: new UntypedFormControl('', [Validators.required, Validators.email]),    
  });
  errormessage = null;  
  constructor(private http: HttpService, private router: Router) { }

  doLogin() {
    /*let kdsuser: KDSUser = new KDSUser();
    kdsuser.email = this.loginForm.get("email").value;
    kdsuser.password = this.loginForm.get("password").value;
    kdsuser.user_type_id = 1;
    console.log(kdsuser);
    this.http.KDSLogin(kdsuser).subscribe( (data) => {
      if (data.id > 0){
        this.kdsstore.setActiveUser(data);
        this.router.navigateByUrl('/kds/home');         
      } else {        
        this.errormessage = "Login Failed, Please Try Again";
      }
    });*/
  }

  ngOnInit(): void {
    /*this.kdsstore.getActiveUser().subscribe( (user : KDSUser) => {
      if (undefined !== user && user.id > 0){
        this.kdsstore.setActiveUser(user);  
        this.router.navigateByUrl('/kds/home');    
      }       
    });*/
  }

}
