import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { KDSPermission } from 'src/app/datatypes/kds/kdspermissions';
import { KDSUser } from 'src/app/datatypes/kds/kdsuser';
import { HttpService } from 'src/app/services/http.service';
import { KDSQuery } from 'src/app/services/kds.query';

@Component({
  selector: 'app-kds-login',
  templateUrl: './kds-login.component.html',
  styleUrls: ['./kds-login.component.css']
})
export class KdsLoginComponent implements OnInit {

  loginForm = new UntypedFormGroup({    
    password: new UntypedFormControl('', Validators.required),
    email: new UntypedFormControl('', [Validators.required, Validators.email]),    
  });
  errormessage = null;  
  constructor(private http: HttpService, private kdsstore: KDSQuery, private router: Router) { }

  doLogin() {
    let kdsuser: KDSUser = new KDSUser();
    kdsuser.email = this.loginForm.get("email").value;
    kdsuser.password = this.loginForm.get("password").value;
    kdsuser.user_type_id = 1;
    console.log(kdsuser);
    this.http.KDSLogin(kdsuser).subscribe( (data) => {
      if (data.id > 0){
        //user logged in , get permissions
        this.http.KDSGetUserTypePermissions(data.user_type_id).subscribe( (resp : KDSPermission[]) => {
          console.log(resp);
          this.kdsstore.setPermissions(resp);
          this.kdsstore.setActiveUser(data);
          this.router.navigateByUrl('/kds/home');
        });                 
      } else {        
        this.errormessage = "Login Failed, Please Try Again";
      }
    });
  }

  ngOnInit(): void {
    this.kdsstore.getActiveUser().subscribe( (user : KDSUser) => {
      if (undefined !== user && user.id > 0){
        this.kdsstore.setActiveUser(user);  
        this.router.navigateByUrl('/kds/home');    
      }       
    });
  }

}
