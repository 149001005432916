import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { APPMESSAGES } from 'src/app/datatypes/generic/messages';
import { KDSUser } from 'src/app/datatypes/kds/kdsuser';
import { AuthService } from 'src/app/services/auth.service';
import { HttpService } from 'src/app/services/http.service';
import { DialogUserPermissionsManageComponent } from 'src/app/kds/dialog-user-permissions-manage/dialog-user-permissions-manage.component';
import { DialogUsersManageComponent } from '../../dialogs/dialog-users-manage/dialog-users-manage.component';
import { DialogConfirmComponent } from 'src/app/components/dialog-confirm/dialog-confirm.component';

@Component({
  selector: 'app-kds-users-manage',
  templateUrl: './kds-users-manage.component.html',
  styleUrls: ['./kds-users-manage.component.css']
})
export class KdsUsersManageComponent implements OnInit {

  public showLoading = true;
  public userAccounts : Array<KDSUser> = new Array<KDSUser>();
  public displayedColumns: string[] = ['email', 'user_type_id', 'actions'];
  public localUser : KDSUser = new KDSUser();

  constructor(private auth: AuthService, public dialog: MatDialog, private http: HttpService) { }

  addNewUser(){
    const dialogRef = this.dialog.open(DialogUsersManageComponent);
    dialogRef.afterClosed().subscribe((data) => { 
      this.loadUsers();
    });
  }

  userType(usertypeid : number){
    if (usertypeid === 1){
      return "ADMIN USER";
    } else if (usertypeid === 2){
      return "RESELLER USER";
    } else if (usertypeid === 3){
      return "REPORT USER";
    } else if (usertypeid === 4){
      return "API USER";
    }
  }

  deleteUser(element){    
    const dialogRef = this.dialog.open(DialogConfirmComponent , {
      data : { title: 'CONFIRM USER DELETE' , message: 'Do you want to delete this user ?' }
    });
    dialogRef.afterClosed().subscribe((data) => { 
      if (data === APPMESSAGES.CONFIRM_YES) {
        this.http.KDSDeleteUser(element.id as number).subscribe(resp => {
          console.log(resp);  
          this.loadUsers();        
        });
      }      
    });
  }

  loadUsers(){
    this.http.KDSGETUsersForUser(this.localUser.id).subscribe( accounts => {   
      console.log(this.localUser.id);     
      this.userAccounts = accounts;
    });
  };

  editPermissions(e: KDSUser){    
    const dialogRef = this.dialog.open(DialogUserPermissionsManageComponent , {data : { user : e } });
    dialogRef.afterClosed().subscribe((data) => { 
      
    });
  }

  ngOnInit(): void {
    this.auth.checkKDSUser((user : KDSUser) => {
      this.localUser = user;
      this.showLoading = false;
      this.loadUsers();
    });
  }

}
