<h2 mat-dialog-title>Manage User Type</h2>
<mat-dialog-content>
    Permissions this user type has access to
    <mat-grid-list style="width:400px;" rowHeight="200px" cols="1">
        <mat-grid-tile style="width:400px;">
            <mat-form-field style="width:400px;" appearance="outline">
                <mat-label>Permissions</mat-label>
                <mat-select style="width:400px;" [formControl]="userTypePermissions" multiple>
                  <mat-option *ngFor="let permission of permissions" [value]="permission.id">{{permission.name}}</mat-option>
                </mat-select>
              </mat-form-field>
        </mat-grid-tile>
    </mat-grid-list>
</mat-dialog-content>
<mat-dialog-actions>
    <button type="submit" (click)="updateperms()" mat-raised-button color="primary">UPDATE PERMISSIONS</button>  
    <button mat-button [mat-dialog-close]="true">Cancel</button>
</mat-dialog-actions>