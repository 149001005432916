<mat-toolbar color="primary">
  <div *ngIf="undefined !== localUser && (localUser.user_type_id == 1 || localUser.user_type_id == 2)">
    <button routerLink="/kds/accounts" style="min-width:150px;" color="light" mat-icon-button aria-label="accounts">
      <mat-icon>account_circle</mat-icon>
      ACCOUNTS
    </button> |
  </div>
  <div *ngIf="undefined !== localUser && (localUser.user_type_id == 1 || localUser.user_type_id == 2)">
    <button routerLink="/kds/terminals" style="min-width:150px;" color="light" mat-icon-button aria-label="accounts">
      <mat-icon>branding_watermark</mat-icon>
      TERMINALS 
    </button> | 
  </div>
  <div *ngIf="undefined !== localUser && (localUser.user_type_id == 3 || localUser.user_type_id == 1)">    
    <button routerLink="/kds/reports" style="min-width:150px;" color="light" mat-icon-button aria-label="accounts">
      <mat-icon>branding_watermark</mat-icon>
      REPORTS 
    </button> | 
  </div>
 
  <div *ngIf="permUser.showUsers">    
    <button [matMenuTriggerFor]="usersmenu" style="min-width:100px;" color="light" mat-icon-button aria-label="usersmenu">      
      USERS  
    </button> |
    <mat-menu #usersmenu="matMenu"> 
      <button routerLink="/kds/user/users" mat-menu-item>        
        <span>Manage Users</span>
      </button>
    </mat-menu>
  </div>

  <div *ngIf="permUser.showGroups">    
    <button [matMenuTriggerFor]="usersmenu" style="min-width:100px;" color="light" mat-icon-button aria-label="usersmenu">      
      GROUPS  
    </button> |
    <mat-menu #usersmenu="matMenu"> 
      <button routerLink="/kds/user/users" mat-menu-item>        
        <span>Manage Users</span>
      </button>
    </mat-menu>
  </div>

  <div *ngIf="undefined !== localUser && localUser.user_type_id == 1">    
    <button [matMenuTriggerFor]="adminmenu" style="min-width:150px;" color="light" mat-icon-button aria-label="accounts">
      <mat-icon>admin_panel_settings</mat-icon>
      ADMIN 
    </button> |
    <mat-menu #adminmenu="matMenu"> 
      <button routerLink="/kds/users" mat-menu-item>
        <mat-icon>person_add</mat-icon>
        <span>User Admin</span>
      </button>
      <button mat-menu-item>
        <mat-icon>person_add</mat-icon> 
        <span>User Account Permissions</span>
      </button>
      <button mat-menu-item>
        <mat-icon>person_add</mat-icon> 
        <span>User Type Permissions</span>
      </button>
      <button routerLink="/kds/permissions" mat-menu-item>        
        <span>Web Site Permissions</span>
      </button>      
      <button routerLink="/kds/usertypepermissions" mat-menu-item>        
        <span>User Type Permissions</span>
      </button>
      <!--<button mat-menu-item>
        <mat-icon>notifications_off</mat-icon>
        <span>Disable alerts</span>
      </button>-->
    </mat-menu>
</div>
<!-- <div *ngIf="undefined !== localUser && localUser.user_type_id == 1">
  <button routerLink="/kds/admin-statistics" style="min-width:150px;" color="light" mat-icon-button aria-label="statistics">
    <mat-icon>insert_chart_outlined</mat-icon>
     ADMIN REPORTS 
  </button> | 
</div> -->
  <span class="example-spacer"></span>
  <button (click)="userLogout()" class="right-align" style="min-width:200px; float:right;" color="light" mat-icon-button aria-label="logout">
    <mat-icon>logout</mat-icon>
    LOGOUT
  </button>
</mat-toolbar>
