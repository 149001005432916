<!--<div class="container100 center-align">
  <nav>
    <div class="nav-wrapper">
      <a href="#" data-target="mobile-demo" class="sidenav-trigger"><i class="material-icons">menu</i></a>
      <ul class="left hide-on-med-and-down">
        <li><a href="sass.html">Accounts</a></li>
        <li><a href="badges.html">Terminals</a></li>
        <li><a href="mobile.html">Logout</a></li>
      </ul>
    </div>
  </nav>

  <ul class="sidenav" id="mobile-demo">
    <li><a href="sass.html">Sass</a></li>
    <li><a href="badges.html">Components</a></li>
    <li><a href="collapsible.html">Javascript</a></li>
    <li><a href="mobile.html">Mobile</a></li>
  </ul>

  <div class="row">
    <div class="col s12 m12">
      <h4>Accounts</h4>
      <a class="btn-floating"><i class="material-icons">add</i></a>
    </div>
  </div>
  <div class="row">
    <div class="col s12 m6">
      <div class="card blue-grey darken-1">
        <div class="card-content white-text">
          <span class="card-title">Card Title</span>
          <p>I am a very simple card. I am good at containing small bits of information.
            I am convenient because I require little markup to use effectively.</p>
        </div>
        <div class="card-action">
          <a href="#">Terminals</a>
          <a href="#">Delete</a>
        </div>
      </div>
    </div>
  </div>

  
  <a class="waves-effect waves-light btn modal-trigger" href="#modal1">Modal</a>

  
  <div id="modal1" class="modal">
    <div class="modal-content">
      <h4>Modal Header</h4>
      <p>A bunch of text</p>
    </div>
    <div class="modal-footer">
      <a href="#!" class="modal-close waves-effect waves-green btn-flat">Agree</a>
    </div>
  </div>
  
</div> -->
<app-kds-partial-header></app-kds-partial-header>

