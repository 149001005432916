<h2 mat-dialog-title>Add New Terminal</h2>
<mat-dialog-content>
    Terminal is a single KDS display screen. It will generate a connection code to use from the KDS software.
    <mat-grid-list rowHeight="200px" cols="1">
        <mat-grid-tile>
          <form [formGroup]="newterminalForm">
            <mat-form-field style="width:400px;" appearance="outline">
              <mat-label>Terminal Name</mat-label>
              <input matInput formControlName="name" placeholder="Name">
              <mat-error *ngIf="newterminalForm.get('name').invalid">Invalid Name</mat-error>
            </mat-form-field>                        
            <br />
            <mat-form-field style="min-width:400px;" appearance="fill">
                <mat-label>PLEASE SELECT ACCOUNT</mat-label>
                <mat-select formControlName="account_id" (selectionChange)="changed($event)" >
                  <mat-form-field style="width:100%;" appearance="fill">
                    <mat-label>Search</mat-label>
                    <input [(ngModel)]="searchTxt" [ngModelOptions]="{standalone: true}" matInput placeholder="search">

                  </mat-form-field>
                  <!-- <mat-option *ngFor="let account of this.accountsData; let i = index" value="{{account.id}}">{{account.name}}</mat-option>   -->
                  <mat-option *ngFor="let account of this.accountsData | search : searchTxt; let i = index" [value]="account.id">{{account.name}}</mat-option>

                </mat-select>
                <mat-error *ngIf="newterminalForm.get('account_id').invalid">Invalid Account</mat-error>
              </mat-form-field>                        
          </form>
        </mat-grid-tile>
    </mat-grid-list>
</mat-dialog-content>
<mat-dialog-actions>
    <button type="submit" (click)="addTerminal()" [disabled]="!newterminalForm.valid" mat-raised-button color="primary">SAVE TERMINAL</button>  
    <button mat-button [mat-dialog-close]="true">Cancel</button>
</mat-dialog-actions>
