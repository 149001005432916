import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DialogConfirmComponent } from 'src/app/components/dialog-confirm/dialog-confirm.component';
import { APPMESSAGES } from 'src/app/datatypes/generic/messages';
import { KDSQuery } from 'src/app/services/kds.query';
import { akitaConfig } from '@datorama/akita';
import { KDSUser } from 'src/app/datatypes/kds/kdsuser';
import { GlobalService } from 'src/app/services/global.service';
import { KDSTopLevelPermission } from 'src/app/datatypes/kds/generic';

akitaConfig({ resettable: true });
@Component({
  selector: 'app-kds-partial-header',
  templateUrl: './kds-partial-header.component.html',
  styleUrls: ['./kds-partial-header.component.css']
})
export class KdsPartialHeaderComponent implements OnInit {
  public localUser : KDSUser = new KDSUser();  
  public permUser : KDSTopLevelPermission = new KDSTopLevelPermission();

  public showUsers = false;
  public showGroups = false;
  constructor(public global: GlobalService, private kds: KDSQuery, private router: Router, public dialog: MatDialog) { }

  checkUser() {
    this.global.getKDSUserPermissions().then( (data) => {
      this.permUser = data;
    });    
  };

  userLogout(){
    const dialogRef = this.dialog.open(DialogConfirmComponent , {
      data : { title: 'CONFIRM LOGOUT' , message: 'Do you want to logout ?' }
    });
    dialogRef.afterClosed().subscribe((data) => { 
      if (data === APPMESSAGES.CONFIRM_YES) {
        this.kds.setUserLogout();
        this.router.navigateByUrl('/'); 
      }      
    });    
  }  
  ngOnInit(): void {
    this.kds.getActiveUser().subscribe( (user) => {
      if (undefined !== user && user.id > 0) {
        this.localUser = user;     
        this.checkUser();   
      }
    });
  }

}
