import { Component, OnInit } from '@angular/core';
 
@Component({
  selector: 'app-login-component',
  templateUrl: './login-component.component.html',
  styleUrls: ['./login-component.component.css']
})
export class LoginComponentComponent implements OnInit {

  constructor() { }
  
  dwservice(){
    window.open( "https://www.dwservice.net/en/download.html", "_blank");
  }

  downloadArmDeb(){
    window.open("https://kdsdailybackup.s3-eu-west-1.amazonaws.com/releases/dist/kdstabletpos-latest-armv7l.deb", "_blank");
  }

  downloadWindows(){
    window.open("https://kdsdailybackup.s3-eu-west-1.amazonaws.com/releases/dist/kdstabletpos-latest.exe", "_blank");
  }

  downloadLinuxDeb(){
    window.open("https://kdsdailybackup.s3-eu-west-1.amazonaws.com/releases/dist/kdstabletpos-latest-amd64.deb", "_blank");
  }

  downloadArmDebv2(){
    window.open("https://kdsdailybackup.s3-eu-west-1.amazonaws.com/kds2/releases/linux/armv7l/kds-tabletpos_armv7l.deb", "_blank");
  }

  downloadWindows64v2(){
    window.open("https://kdsdailybackup.s3-eu-west-1.amazonaws.com/kds2/releases/windows/x64/kds-tabletpos_x64.exe", "_blank");
  }

  downloadLinuxDebv2(){
    window.open("https://kdsdailybackup.s3-eu-west-1.amazonaws.com/kds2/releases/linux/x64/kds-tabletpos_amd64.deb", "_blank");
  }
  
  ngOnInit(): void {
  }

}
