<app-generic-loading *ngIf="showLoading"></app-generic-loading>

<div *ngIf="!showLoading">
  <app-kds-partial-header></app-kds-partial-header>
  <div style="padding:10px;">
    <mat-card class="example-card">
      <mat-card-header>
        <mat-card-title>KDS Users</mat-card-title>
        <mat-card-subtitle>User Management</mat-card-subtitle>
      </mat-card-header>
      <mat-card-actions>
        <button (click)="addNewUser()" mat-mini-fab>+</button>
      </mat-card-actions>
    </mat-card>  
    <br />

  <div *ngIf="!(this.userAccounts.length > 0)">
    <h1>You have no users yet</h1>
  </div>

  <table style="width:100%;" *ngIf="this.userAccounts.length > 0" mat-table [dataSource]="userAccounts" class="mat-elevation-z8">

    <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->

    <!-- Name Column -->
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef> Email </th>
      <td mat-cell *matCellDef="let element"> {{element.email}} </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="user_type_id">
      <th mat-header-cell *matHeaderCellDef> User Type </th>
      <td mat-cell *matCellDef="let element"> {{ userType(element.user_type_id) }} </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef> Actions </th>
      <td mat-cell *matCellDef="let element">        
        <button (click)="deleteUser(element)" mat-raised-button color="warn">DELETE USER</button> |  
        <button (click)="editPermissions(element)" *ngIf="element.user_type_id === 3 || element.user_type_id === 4" mat-raised-button color="warn">PERMISSIONS</button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
</div>
