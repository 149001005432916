<!--<div class="container100 valign-wrapper center-align"> 
    <div class="row blue lighten-5">
        <form style="min-width:500px;" [formGroup]="registerForm" class="col m12">      
            <div class="row">
                <h4>KDS REGISTER</h4>
                
                <div class="input-field col m12">
                    <i class="material-icons prefix">account_circle</i>
                    <input placeholder="Email" formControlName="email" id="email" type="email" class="validate">
                    <label for="username">Email</label>
                </div>
            </div>
            <div class="row">
                <div class="input-field col m12">
                    <i class="material-icons prefix">account_circle</i>
                    <input id="password" formControlName="password" type="password" class="validate">
                    <label for="password">Password</label>                
                </div>
            </div>    
            <div class="row">
                <div class="col m12 center-align">
                    
                </div>
            </div>                      
          <div class="row">
            <div class="col m12 center-align">
                <button [disabled]="!registerForm.valid" (click)="doRegister()" class="left-align btn waves-effect waves-light" type="button" name="action">REGISTER
                    <i class="material-icons right">app_registration</i>
                </button> 
                 | 
                <button class="left-align btn waves-effect waves-light" routerLink="/kds/login" type="button" name="action">LOGIN
                    <i class="material-icons right">send</i>
                </button>                
            </div>
            
            <div class="col m12 center-align">
                <p></p>
                <button class="btn waves-effect waves-light" type="button" name="action">RESET PASSWORD
                    <i class="material-icons right">reset_tv</i>
                </button>
            </div>
          </div>
        </form>
      </div>
      </div>
    -->

<mat-toolbar color="primary">
  <span style="margin:auto;">REGISTER FOR KDS ACCOUNT</span>
</mat-toolbar>
<mat-divider></mat-divider>

<mat-grid-list rowHeight="400px" cols="1">
    <mat-grid-tile>
        <form [formGroup]="registerForm">
            <mat-form-field style="width:400px;" appearance="outline">
                <mat-label>Email</mat-label>
                <input matInput formControlName="email" placeholder="Email">    
                <mat-error *ngIf="registerForm.get('email').invalid">Invalid Email</mat-error>          
            </mat-form-field>
            <br />
            <mat-form-field style="width:400px;" appearance="outline">
                <mat-label>Password</mat-label>
                <input matInput formControlName="password" placeholder="Password">  
                <mat-error *ngIf="registerForm.get('email').invalid">Please Enter Password</mat-error>
            </mat-form-field>
            
            <br />
            <button type="submit" (click)="doRegister()" [disabled]="!registerForm.valid" mat-raised-button color="primary">REGISTER</button> | 
            <button type="button" routerLink="/kds/login" mat-raised-button color="accent">LOGIN</button>
        </form>
    </mat-grid-tile>    
</mat-grid-list>