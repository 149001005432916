import { KDSAccount, KDSUserAccounts, KDSUserAccountsDB } from 'src/app/datatypes/kds/kdsaccount';
import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { KDSQuery } from 'src/app/services/kds.query';
import { HttpService } from 'src/app/services/http.service';
import { KDSUser } from 'src/app/datatypes/kds/kdsuser';
import { KDSPermission } from 'src/app/datatypes/kds/kdspermissions';
import { KDSUserTypePermission } from 'src/app/datatypes/kds/kdsusertypepermissions';

@Component({
  selector: 'app-dialog-user-type-permission-add',
  templateUrl: './dialog-user-type-permission-add.component.html',
  styleUrls: ['./dialog-user-type-permission-add.component.css']
})
export class DialogUserTypePermissionAddComponent implements OnInit {

  public permissions = Array<KDSPermission>();
  public userTypePermissions = new UntypedFormControl();

  constructor(private kds: KDSQuery, 
    private router: Router, 
    private http: HttpService, 
    public dialogRef: MatDialogRef<DialogUserTypePermissionAddComponent>, @Inject(MAT_DIALOG_DATA) 
    public data: { userTypeId: number }) { }

    updateperms() {      
      this.http.KDSDeleteUserTypePermissions(this.data.userTypeId).subscribe( () => {
        let perms : number[] = this.userTypePermissions.value as Array<number>;
        perms.forEach(item => {
          let n = new KDSUserTypePermission();
          n.permission_id = item;
          n.user_type_id = this.data.userTypeId;
          this.http.KDSSaveUserTypePermissions(n).subscribe( () => {});
        });
        this.dialogRef.close();
      });
    };

    loadUserTypePermissions() {
      this.http.KDSGetUserTypePermissions(this.data.userTypeId).subscribe( (data : KDSPermission[]) => {
        console.log(data);
        if (data.length > 0) {
          let def = new Array<number>();
          data.forEach( entry => {
            def.push(entry.id);            
          });          
          this.userTypePermissions.patchValue(def);
        }
      });
    };

  ngOnInit(): void {
    this.http.KDSGETAllPermissions().subscribe( (data) => {
      this.permissions = data;   
      console.log(this.data);
      this.loadUserTypePermissions();   
    });       
  }

}
