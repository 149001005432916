<app-generic-loading *ngIf="showLoading"></app-generic-loading>

<div *ngIf="!showLoading">
  <app-kds-partial-header></app-kds-partial-header>

  <button mat-fab color="primary" style="float:right; margin-right:5px; margin-top:5px;" matTooltipPosition="left"
    matTooltip="Save Config" (click)="onSave()" aria-label="Save Config">
    <mat-icon>save</mat-icon>
  </button>
  <mat-tab-group>
    <!-- KDS Setup -->
    <mat-tab label="Setup">
      <mat-tab-group id="kdssetup">
        <mat-tab label="KDS Setup">
          <form [formGroup]="KDSSetupForm">
            <mat-card style="margin:10px;">
              <mat-form-field style="width:400px;" appearance="outline">
                <mat-label>KDS Client Name</mat-label>
                <input matInput formControlName="name" placeholder="Client Name">
                <mat-error *ngIf="KDSSetupForm.get('name').invalid">Invalid Client Name</mat-error>
              </mat-form-field>
              <br />
              <mat-form-field style="width:400px;" appearance="outline">
                <mat-label>KDS Terminal Name</mat-label>
                <input matInput formControlName="name" placeholder="Terminal">
                <mat-error *ngIf="KDSSetupForm.get('terminal').invalid">Invalid Terminal Name</mat-error>
              </mat-form-field>
              <br />
              <mat-form-field style="width:400px;" appearance="outline">
                <mat-label>KDS Screen Name</mat-label>
                <input matInput formControlName="screen" placeholder="bar / kitchen / drinks">
                <mat-error *ngIf="KDSSetupForm.get('screen').invalid">Invalid Screen Name</mat-error>
              </mat-form-field>
              <br />
              <mat-form-field style="width:400px;" appearance="outline">
                <mat-label>KDS Serial Code</mat-label>
                <input matInput formControlName="serial" placeholder="Serial Code">
                <mat-error *ngIf="KDSSetupForm.get('serial').invalid">Invalid Serial Code</mat-error>
              </mat-form-field>
            </mat-card>
          </form>
        </mat-tab>
        <mat-tab label="KDS Flags">
          <mat-card style="margin:10px; padding:5px;">
            <form [formGroup]="KDSFlagsForm">
              <mat-grid-list style="height:400px;" cols="2" rowHeight="fit">
                <mat-grid-tile>
                  <mat-card class="checkbox-card">
                    <mat-card-header>
                      <mat-card-title>COMPRESS ITEMS</mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                      <p>this option combines the main items together on the main display</p>
                    </mat-card-content>
                    <mat-card-actions>
                      <mat-checkbox style="width:100%;" formControlName="compressed" class="example-margin">Enable Item
                        Compression</mat-checkbox>
                    </mat-card-actions>
                  </mat-card>
                </mat-grid-tile>

                <mat-grid-tile>
                  <mat-card class="checkbox-card">
                    <mat-card-header>
                      <mat-card-title>ACCOUNT PROFILE HEADINGS</mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                      <p>this option shows the account profile used on the main display per order</p>
                    </mat-card-content>
                    <mat-card-actions>
                      <mat-checkbox formControlName="showheadings" class="example-margin">Show Account Profile Headings
                      </mat-checkbox>
                    </mat-card-actions>
                  </mat-card>
                </mat-grid-tile>
                <mat-grid-tile>
                  <mat-card class="checkbox-card">
                    <mat-card-header>
                      <mat-card-title>ALIGN ALL ITEMS LEFT</mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                      <p>this option left align items and subitems , unchecking it centers the items and subitems</p>
                    </mat-card-content>
                    <mat-card-actions>
                      <mat-checkbox formControlName="leftalign" class="example-margin">Align Main & Sub Items Left
                      </mat-checkbox>
                    </mat-card-actions>
                  </mat-card>
                </mat-grid-tile>
                <mat-grid-tile>
                  <mat-card class="checkbox-card">
                    <mat-card-header>
                      <mat-card-title>SORT SUBITEMS</mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                      <p>this option will sort the subitems alphabetically</p>
                    </mat-card-content>
                    <mat-card-actions>
                      <mat-checkbox formControlName="sortsubitems" class="example-margin">Sort Subitems Alphabetically
                      </mat-checkbox>
                    </mat-card-actions>
                  </mat-card>
                </mat-grid-tile>

                <mat-grid-tile>
                  <mat-card class="checkbox-card">
                    <mat-card-header>
                      <mat-card-title>ADD CHECKBOXES TO ORDERS</mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                      <p>this option allows you to tick of a item from a order by use of checkboxes</p>
                    </mat-card-content>
                    <mat-card-actions>
                      <mat-checkbox formControlName="checkboxes" class="example-margin">Enable Checkboxes Per Item
                      </mat-checkbox>
                    </mat-card-actions>
                  </mat-card>
                </mat-grid-tile>

                <mat-grid-tile>
                  <mat-card class="checkbox-card">
                    <mat-card-header>
                      <mat-card-title>GIVE APP PRIMARY FOCUS</mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                      <p>this option will always give the KDS the primary focus. very useful when using bumpbars.</p>
                    </mat-card-content>
                    <mat-card-actions>
                      <mat-checkbox formControlName="primaryfocus" class="example-margin">Enable Window Top Focus
                      </mat-checkbox>
                    </mat-card-actions>
                  </mat-card>
                </mat-grid-tile>

              </mat-grid-list>
            </form>
          </mat-card>
        </mat-tab>
        <mat-tab label="KDS Timers">
          <mat-card style="margin:10px;">
            <form [formGroup]="KDSColors">
              <mat-form-field style="width:400px;" appearance="outline">
                <mat-label>Late 1 Timer</mat-label>
                <input type="numeric" matInput formControlName="late1time" placeholder="2">
                <mat-error *ngIf="KDSColors.get('late1time').invalid">Invalid Timer</mat-error>
              </mat-form-field> |
              <mat-form-field style="width:400px;" appearance="outline">
                <mat-label>Late 2 Timer</mat-label>
                <input type="numeric" matInput formControlName="late2time" placeholder="4">
                <mat-error *ngIf="KDSColors.get('late2time').invalid">Invalid Timer</mat-error>
              </mat-form-field>
              <br />
              <mat-label>REVERSE COUNTDOWN TIMER</mat-label>
              <br />
              <small>this option will count down the timer from a specified number to 00:01 and stay there.</small>
              <br />
              <mat-checkbox formControlName="reversecount" class="example-margin">Enable reverse timer</mat-checkbox>
              <br />
              <mat-form-field *ngIf="KDSColors.get('reversecount').value" style="width:400px; margin-top:10px;"
                appearance="outline">
                <mat-label>Reverse Timer Beginning (minutes)</mat-label>
                <input type="numeric" matInput formControlName="reversecounttime" placeholder="4">
                <mat-error *ngIf="KDSColors.get('reversecounttime').invalid">Invalid Time</mat-error>
              </mat-form-field>
            </form>
          </mat-card>
        </mat-tab>
        <mat-tab label="KDS Colors">
          <mat-card style="margin:10px;">
            <form [formGroup]="KDSColors">              
              <mat-form-field style="width:400px;" appearance="outline">
                <mat-label>Main Items Background</mat-label>
                <input type="color" matInput formControlName="mainitem">
                <mat-error *ngIf="KDSColors.get('mainitem').invalid">Invalid Color</mat-error>
              </mat-form-field> |
              <mat-form-field style="width:400px;" appearance="outline">
                <mat-label>Sub Items Background</mat-label>
                <input type="color" matInput formControlName="subitem">
                <mat-error *ngIf="KDSColors.get('subitem').invalid">Invalid Color</mat-error>
              </mat-form-field>
              <br />
              <mat-form-field style="width:400px;" appearance="outline">
                <mat-label>Late 1 Color</mat-label>
                <input type="color" matInput formControlName="late1">
                <mat-error *ngIf="KDSColors.get('late1').invalid">Invalid Color</mat-error>
              </mat-form-field> |
              <mat-form-field style="width:400px;" appearance="outline">
                <mat-label>Late 2 Color</mat-label>
                <input type="color" matInput formControlName="late2">
                <mat-error *ngIf="KDSColors.get('late2').invalid">Invalid Color</mat-error>
              </mat-form-field>
              <br />
              <mat-form-field style="width:400px;" appearance="outline">
                <mat-label>Waiter Background Color</mat-label>
                <input type="color" matInput formControlName="waitercolor">
                <mat-error *ngIf="KDSColors.get('waitercolor').invalid">Invalid Color</mat-error>
              </mat-form-field> |
              <mat-form-field style="width:400px;" appearance="outline">
                <mat-label>Course Background sColor</mat-label>
                <input type="color" matInput formControlName="coursecolor">
                <mat-error *ngIf="KDSColors.get('coursecolor').invalid">Invalid Color</mat-error>
              </mat-form-field>
            </form>
          </mat-card>
        </mat-tab>
      </mat-tab-group>
    </mat-tab>

    <!-- Integrations -->
    <mat-tab label="Integrations">
      <mat-tab-group id="integrations">
        <mat-tab label="Twilio Integration">
          <mat-card style="margin:10px;">
            <form [formGroup]="KDSTwilioForm">              
              <mat-checkbox formControlName="enablesms" class="example-margin">Enable SMS</mat-checkbox>
              <br />
              <div style="margin-top:10px;" *ngIf="KDSTwilioForm.get('enablesms').value">
                <mat-form-field style="width:400px;" appearance="outline">
                  <mat-label>Twilio ACCOUNT_SID</mat-label>
                  <input matInput formControlName="twilio_accountsid" placeholder="ACCOUNT_SID">
                  <mat-error *ngIf="KDSTwilioForm.get('twilio_accountsid').invalid">Invalid ACCOUNT_SID</mat-error>
                </mat-form-field>
                <br />
                <mat-form-field style="width:400px;" appearance="outline">
                  <mat-label>Twilio AUTH_TOKEN</mat-label>
                  <input matInput formControlName="twilio_authtoken" placeholder="AUTH_TOKEN">
                  <mat-error *ngIf="KDSTwilioForm.get('twilio_authtoken').invalid">Invalid AUTH_TOKEN</mat-error>
                </mat-form-field>
                <br />
                <mat-form-field style="width:400px;" appearance="outline">
                  <mat-label>Twilio SENDING_NUMBER</mat-label>
                  <input matInput formControlName="twilio_sendingnumber" placeholder="SENDING_NUMBER">
                  <mat-error *ngIf="KDSTwilioForm.get('twilio_sendingnumber').invalid">Invalid SENDING_NUMBER</mat-error>
                </mat-form-field>
                <br />
                <mat-form-field style="width:400px;" appearance="outline">
                  <mat-label>Number Prefix (replace 0 with this number eg. +27)</mat-label>
                  <input matInput formControlName="prefix" placeholder="+27">
                  <mat-error *ngIf="KDSTwilioForm.get('prefix').invalid">Invalid Prefix</mat-error>
                </mat-form-field>
                <br />
                <mat-form-field style="width:600px;" appearance="outline">
                  <mat-label>Twilio Message On Order Complete</mat-label>
                  <textarea matInput formControlName="twilio_message" placeholder="Message"></textarea>
                  <mat-error *ngIf="KDSTwilioForm.get('twilio_message').invalid">Invalid Message</mat-error>
                </mat-form-field>                                
              </div>
            </form>
          </mat-card>
        </mat-tab>
      </mat-tab-group>
    </mat-tab>

    <!-- KDS Extra --> 
    <mat-tab label="KDS Extra">
      <mat-tab-group id="kdsextra">
        <mat-tab label="On Complete">
          <mat-card style="margin:10px;">
            <form [formGroup]="KDSCompleteForm">              
              <h2>On Complete</h2>
              <mat-checkbox formControlName="enablesendprinter" class="example-margin">Enable Send Network Printer
              </mat-checkbox>
              <br />
              <div style="margin-top:10px;" *ngIf="KDSCompleteForm.get('enablesendprinter').value">
                <mat-form-field style="width:400px;" appearance="outline">
                  <mat-label>Network Printer IP</mat-label>
                  <input matInput formControlName="sendprinterip" placeholder="10.0.0.1">
                  <mat-error *ngIf="KDSCompleteForm.get('sendprinterip').invalid">Invalid IP</mat-error>
                </mat-form-field>
                <br />
              </div>
            </form>
          </mat-card>
        </mat-tab>
        <mat-tab label="Duplication">
          <mat-card style="margin:10px;">
            <form [formGroup]="KDSDuplicate">              
              <h2>Duplicate KDS</h2>
              <mat-checkbox formControlName="enableduplication" class="example-margin">Enable Duplication (Master -> Slave)
              </mat-checkbox>
              <br />
              <div style="margin-top:10px;" *ngIf="KDSDuplicate.get('enableduplication').value">
                <mat-form-field class="entry" appearance="outline">
                  <mat-label>Duplicate 1 (M -> S)</mat-label>
                  <input matInput formControlName="screen1" placeholder="10.0.0.1">
                  <mat-error *ngIf="KDSDuplicate.get('screen1').invalid">Invalid IP</mat-error>
                </mat-form-field>
                <br />
                <mat-form-field class="entry" appearance="outline">
                  <mat-label>Duplicate 2 (M -> S)</mat-label>
                  <input matInput formControlName="screen2" placeholder="10.0.0.1">
                  <mat-error *ngIf="KDSDuplicate.get('screen2').invalid">Invalid IP</mat-error>
                </mat-form-field>
                <br />
                <mat-form-field class="entry" appearance="outline">
                  <mat-label>Duplicate 3 (M -> S)</mat-label>
                  <input matInput formControlName="screen3" placeholder="10.0.0.1">
                  <mat-error *ngIf="KDSDuplicate.get('screen3').invalid">Invalid IP</mat-error>
                </mat-form-field>
                <br />
                <mat-form-field style="width:400px;" class="entry" appearance="outline">
                  <mat-label>Message Server Override (M -> M)</mat-label>
                  <input matInput formControlName="msgserveroverride" placeholder="10.0.0.1">
                  <mat-error *ngIf="KDSDuplicate.get('msgserveroverride').invalid">Invalid IP</mat-error>
                </mat-form-field>
                <br />
              </div>
              <mat-form-field class="entry" appearance="outline">
                <mat-label>Remote Update ( M->S->M ) </mat-label>
                <input matInput formControlName="remote" placeholder="10.0.0.1">
                <mat-error *ngIf="KDSDuplicate.get('remote').invalid">Invalid IP</mat-error>
              </mat-form-field>
            </form>
          </mat-card>
        </mat-tab>
        <mat-tab label="Priority Keyword">
          <mat-card style="margin:10px;">
          <form [formGroup]="KDSFeatures">            
            <h2>Priority Keyword</h2>
            <p>this option will push any orders containing the keyword to the top</p>
            <mat-checkbox formControlName="prioritykeywordenabled" class="example-margin">Enable Priority Keyword
            </mat-checkbox>
            <br />
            <div style="margin-top:10px;" *ngIf="KDSFeatures.get('prioritykeywordenabled').value">
              <mat-form-field style="width:400px;" appearance="outline">
                <mat-label>Keyword</mat-label>
                <input matInput formControlName="prioritykeywordtext" placeholder="Priority">
                <mat-error *ngIf="KDSFeatures.get('prioritykeywordtext').invalid">Invalid Keyword</mat-error>
              </mat-form-field>
              <br />            
            </div>                      
          </form>
        </mat-card>
        </mat-tab>
      </mat-tab-group>
    </mat-tab>
    <!-- END KDS Extra --> 
    

       


    <!-- KDS Instructions -->
    <mat-tab label="KDS Instructions">
      <mat-tab-group id="kdsinstructions">
        <mat-tab label="KDS Status Checks">
          <mat-card style="margin:10px;">
            <h2>KDS Status</h2>
            <button type="button" (click)="onCheckStatus()" mat-raised-button color="primary">CHECK KDS STATUS</button>
            <input style="margin-top:10px;" [(ngModel)]="kdsStatus" matInput readonly="true" placeholder="UNKNOWN">
            <br />
          </mat-card>
        </mat-tab>

      </mat-tab-group>
      <mat-card style="margin:10px;">
       
        
        <button type="button" (click)="onUpdateService()" [disabled]="kdsStatus !== 'ONLINE'" mat-raised-button
          color="primary">UPDATE SERVICES</button>
        <br />
        <br />
        <button type="button" (click)="onSyncConfig()" [disabled]="kdsStatus !== 'ONLINE'" mat-raised-button
          color="primary">SYNC CONFIG</button>
        <br />
        <br />
        <button type="button" (click)="onGetHWID()" [disabled]="kdsStatus !== 'ONLINE'" mat-raised-button
          color="primary">GET HWID</button>
        <br />
        <input style="margin-top:10px;" type="text" [(ngModel)]="kdsHWID" matInput readonly="true" id="hwid_system" />
        <br />
      </mat-card>
      <mat-card style="margin:10px;">
        <h2>Import Data</h2>
        <form [formGroup]="range">

          <mat-label>Enter a date range</mat-label>
          <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
            <input matStartDate formControlName="start" placeholder="Start date">
            <input matEndDate formControlName="end" placeholder="End date">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>

          <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
          <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
          <br />
        </form>
        <button type="button" (click)="onRangeImport()" mat-raised-button color="primary">IMPORT DATA</button>
        <br />
      </mat-card>
    </mat-tab>

  </mat-tab-group>
</div>