import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { KDSQuery } from 'src/app/services/kds.query';
import { HttpService } from 'src/app/services/http.service';
import { KDSAccount } from 'src/app/datatypes/kds/kdsaccount';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { APPMESSAGES } from 'src/app/datatypes/generic/messages';

@Component({
  selector: 'app-dialog-account-add',
  templateUrl: './dialog-account-add.component.html',
  styleUrls: ['./dialog-account-add.component.css']
})
export class DialogAccountAddComponent implements OnInit {

  newaccountForm = new UntypedFormGroup({
    name: new UntypedFormControl('', Validators.required),
    user_id: new UntypedFormControl('', [Validators.required]),
    id: new UntypedFormControl(0),
    created_at: new UntypedFormControl(''), 
  });

  constructor(private kds: KDSQuery, private router: Router, private http: HttpService, public dialogRef: MatDialogRef<DialogAccountAddComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { account: KDSAccount }) { }

  addAccount() {
    let kdsacc : KDSAccount = this.newaccountForm.value as KDSAccount;     
    delete kdsacc.created_at;  
    this.http.KDSCreateAccount(kdsacc).subscribe((httpdata) => {
      this.dialogRef.close(APPMESSAGES.KDS_ACCOUNT_ADDED);
    });
  }

  ngOnInit(): void {
    this.kds.getActiveUser().subscribe((user) => {
      if (undefined !== user && user.id > 0) {
        this.kds.setActiveUser(user);
        this.newaccountForm.get('user_id').setValue(user.id);
        if (this.data !== null) {
          this.newaccountForm.patchValue(this.data.account);
        }
      } else {
        this.router.navigateByUrl('/kds/login');
      }
    });
  }
}
