import { Component, Input, OnInit } from '@angular/core';
import { KDSUserReports } from 'src/app/datatypes/kds/kdsuserreports';
import { Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-kds-partial-user-reports-table',
  templateUrl: './kds-partial-user-reports-table.component.html',
  styleUrls: ['./kds-partial-user-reports-table.component.css']
})
export class KdsPartialUserReportsTableComponent implements OnInit {
  @Input() reports : KDSUserReports[];
  @Output() onrefresh = new EventEmitter<string>();
  @Output() ondelete = new EventEmitter<number>();

  public displayedColumns: string[] = ['desc' , 'name' , 'actions'];
  constructor() { 
    console.log(this.reports);
  }

  rereshReports() {
    this.onrefresh.emit("refresh");
  }

  deleteReport(id : number){
    this.ondelete.emit(id);
  }

  ngOnInit(): void {
  }

}
