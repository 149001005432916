import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { KdsAccountsComponent } from './kds/kds-accounts/kds-accounts.component';
import { KdsConfigComponent } from './kds/kds-config/kds-config.component';
import { KdsHomeComponent } from './kds/kds-home/kds-home.component';
import { KdsLoginComponent } from './kds/kds-login/kds-login.component';
import { KdsPermissionsComponent } from './kds/kds-permissions/kds-permissions.component';
import { KdsRegisterComponent } from './kds/kds-register/kds-register.component';
import { KdsReportsComponent } from './kds/kds-reports/kds-reports.component';
import { KdsTerminalsComponent } from './kds/kds-terminals/kds-terminals.component';
import { KdsUsersComponent } from './kds/kds-users/kds-users.component';
import { KdsUserTypePermissionsComponent } from './kds/pages/kds-user-type-permissions/kds-user-type-permissions.component';
import { KdsUsersManageComponent } from './kds/pages/kds-users-manage/kds-users-manage.component';
import { LoginComponentComponent } from './login-component/login-component.component';
import { YlaLoginComponent } from './yla/yla-login/yla-login.component';

const routes: Routes = [
  { path: '', component: LoginComponentComponent },
  { path: 'yla/login', component: YlaLoginComponent },


  { path: 'kds/login', component: KdsLoginComponent },
  { path: 'kds/register', component: KdsRegisterComponent },
  { path: 'kds/home', component: KdsHomeComponent },
  { path: 'kds/accounts', component: KdsAccountsComponent },
  { path: 'kds/terminals', component: KdsTerminalsComponent },
  { path: 'kds/users', component: KdsUsersComponent },
  { path: 'kds/config', component: KdsConfigComponent },
  { path: 'kds/config/:id', component: KdsConfigComponent },
  { path: 'kds/reports', component: KdsReportsComponent },
  { path: 'kds/permissions', component: KdsPermissionsComponent },
  { path: 'kds/usertypepermissions', component: KdsUserTypePermissionsComponent },
  { path: 'kds/user/users', component: KdsUsersManageComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
