<div class="report-wrapper mat-elevation-z8">
  <h2>User Reports</h2>
  <button (click)="rereshReports()" mat-raised-button color="accent">REFRESH REPORTS</button>
  <table *ngIf="this.reports.length > 0" mat-table [dataSource]="this.reports">

    <ng-container matColumnDef="desc">
      <th mat-header-cell *matHeaderCellDef> Description </th>
      <td mat-cell *matCellDef="let element"> {{element.desc}} </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Name </th>
      <td mat-cell *matCellDef="let element"> <a target="_blank"
          href="https://kdsdailybackup.s3.eu-west-1.amazonaws.com/{{element.name}}">Download Report</a></td>
    </ng-container>

    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef> Actions </th>
        <td mat-cell *matCellDef="let element">                 
          <button (click)="deleteReport(element.id)" mat-raised-button color="warn">DELETE REPORT</button>                    
        </td>
      </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>