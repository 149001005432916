<h2 mat-dialog-title>Add New User</h2>
<mat-dialog-content style="min-width:400px;">
  <p>Add a new user to the system.</p>
  <mat-grid-list cols="1">
    <mat-grid-tile>
      <form [formGroup]="newUserForm">
        <mat-form-field style="width:400px;" appearance="outline">
          <mat-label>Username (Email)</mat-label>
          <input matInput formControlName="email" placeholder="Email">
          <mat-error *ngIf="newUserForm.get('email').invalid">Invalid Email</mat-error>
        </mat-form-field>
        <br />
        <mat-form-field style="width:400px;" appearance="outline">
          <mat-label>Password</mat-label>
          <input matInput formControlName="password" placeholder="Password">
          <mat-error *ngIf="newUserForm.get('password').invalid">Invalid Password</mat-error>
        </mat-form-field>
        <mat-form-field style="min-width:400px;" appearance="fill">
          <mat-label>PLEASE SELECT USER TYPE</mat-label>
          <mat-select formControlName="user_type_id">
            <mat-option *ngIf="canAddAdmin" value="1">ADMIN</mat-option>
            <mat-option *ngIf="canAddResller" value="2">RESELLER</mat-option>
            <mat-option *ngIf="canAddReport" value="3">REPORT USER</mat-option>
            <mat-option *ngIf="canAddApi" value="4">API USER</mat-option>
          </mat-select>
          <mat-error *ngIf="newUserForm.get('user_type_id').invalid">Invalid Account Type</mat-error>
        </mat-form-field>
      </form>
    </mat-grid-tile>
  </mat-grid-list>
</mat-dialog-content>
<mat-dialog-actions>
  <button type="button" (click)="addUser()" [disabled]="!newUserForm.valid" mat-raised-button color="primary">ADD
    USER</button>
  <button mat-button [mat-dialog-close]="true">Cancel</button>
</mat-dialog-actions>
