<mat-toolbar color="primary">
    <span style="margin:auto;">PLEASE LOGIN</span>
  </mat-toolbar>
  <mat-divider></mat-divider>
  
  <mat-grid-list rowHeight="400px" cols="1">
    <mat-grid-tile>
      <form [formGroup]="loginForm">
        <mat-form-field style="width:400px;" appearance="outline">
          <mat-label>Email</mat-label>
          <input matInput formControlName="email" placeholder="Email">
          <mat-error *ngIf="loginForm.get('email').invalid">Invalid Email</mat-error>
        </mat-form-field>
        <br />
        <mat-form-field style="width:400px;" appearance="outline">
          <mat-label>Password</mat-label>
          <input type="password" matInput formControlName="password" placeholder="Password">
          <mat-error *ngIf="loginForm.get('email').invalid">Please Enter Password</mat-error>
        </mat-form-field>
        <mat-error *ngIf="errormessage">{{errormessage}}</mat-error>
        <br />
        <button type="submit" (click)="doLogin()" [disabled]="!loginForm.valid" mat-raised-button color="primary">LOGIN</button> <!-- |
        <button type="button" routerLink="/kds/register" mat-raised-button color="accent">REGISTER</button> -->
      </form>
    </mat-grid-tile>
  </mat-grid-list>
  