import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/services/http.service';
import { KDSQuery } from 'src/app/services/kds.query';
import {MatFormFieldModule} from '@angular/material/form-field';
import { DialogTerminalAddComponent } from '../dialog-terminal-add/dialog-terminal-add.component';
import { APPMESSAGES } from 'src/app/datatypes/generic/messages';
import { KDSTerminal } from 'src/app/datatypes/kds/kdsterminal';
import { DialogConfirmComponent } from 'src/app/components/dialog-confirm/dialog-confirm.component';
import { KDSTerminalMessage } from 'src/app/datatypes/kds/kdsterminalmessage';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { KDSAccount } from 'src/app/datatypes/kds/kdsaccount';
import { startWith , map } from 'rxjs/operators';

@Component({
  selector: 'app-kds-terminals',
  templateUrl: './kds-terminals.component.html',
  styleUrls: ['./kds-terminals.component.css']
})
export class KdsTerminalsComponent implements OnInit {

  public showLoading = true;
  public accountsData : Array<KDSAccount> = new Array<KDSAccount>();
  public filteredaccountsData : Array<KDSAccount> = new Array<KDSAccount>();
  public terminalsData = [];
  public activeAccount = null;
  filterAccountControl = new UntypedFormControl();
  public displayedColumns: string[] = ['name', 'connection_code' , 'hwid' , 'created_at', 'actions'];

  constructor(private kds: KDSQuery, private router: Router, private http: HttpService, public dialog: MatDialog) { }

  private _filterAccounts(value: string): KDSAccount[] {    
    const filterValue = value.toLowerCase();
    return this.accountsData.filter( option => option.name.toLowerCase().includes(filterValue) );
  }
  
  inUse(element: KDSTerminal){
    if (element.hwid.length > 0){
      return "YES";
    } else {
      return "NO";
    }
  }
  addTerminal(){
    let newtermin = new KDSTerminal();
    newtermin.account_id = this.activeAccount;
    const dialogRef = this.dialog.open(DialogTerminalAddComponent , {data : { terminal : newtermin } });
    dialogRef.afterClosed().subscribe((data) => { 
      if (data === APPMESSAGES.KDS_ENTRY_ADDED){
        this.loadTerminalsForUserID(this.activeAccount);
      }
    });
  }
  editTerminal(element){    
    const dialogRef = this.dialog.open(DialogTerminalAddComponent , { data : {
      terminal : element
    }});
    dialogRef.afterClosed().subscribe((data) => {       
      if (data === APPMESSAGES.KDS_ENTRY_ADDED){             
        this.loadTerminalsForUserID(this.activeAccount);
      }
    });
  }
  deleteTerminal(id){    
    const dialogRef = this.dialog.open(DialogConfirmComponent , {
      data : { title: 'CONFIRM TERMINAL DELETE' , message: 'Do you want to delete this terminal ?' }
    });
    dialogRef.afterClosed().subscribe((data) => { 
      if (data === APPMESSAGES.CONFIRM_YES) {
        this.http.KDSDeleteTerminal(id as number).subscribe(resp => {
          console.log(resp);
          this.loadTerminalsForUserID(this.activeAccount);
        });
      }      
    });
  }
  changed(event) {    
    this.activeAccount = event.value;
    this.loadTerminalsForUserID(this.activeAccount);
  }
  loadTerminalsForUserID(id){
    this.http.KDSGetTerminals(id).subscribe( (data) => {      
      this.terminalsData = data;
    });
  }
  loadAccounts(){
    this.kds.getActiveUser().subscribe( (user) => {
      if (undefined !== user && user.id > 0) {
        this.kds.setActiveUser(user);  
        if (this.kds.getUserAccounts().length < 1) {
          this.http.KDSGetAccounts(user.id).subscribe( (httpdata) => {            
            this.accountsData = httpdata;            
            this.filteredaccountsData = this.accountsData;
            this.showLoading = false;
            this.kds.setActiveAccounts(this.accountsData); 
          });      
        } else {
          this.accountsData = this.kds.getUserAccounts();
          this.filteredaccountsData = this.accountsData;
          this.showLoading = false;
        }
      } else {
        this.router.navigateByUrl('/kds/login');
      }      
    });
  }
  loadTerminals() {
    this.kds.getActiveUser().subscribe( (user) => {
      if (user.id > 0){
        this.kds.setActiveUser(user);  
      }
    });
  }
  sendMessage(terminal : KDSTerminal) {
    let mes = new KDSTerminalMessage();
    mes.message = "SEND_DATA";
    mes.terminal_id = terminal.id;
    mes.type = "SEND_DATA_ALL";
    this.http.KDSCreateTerminalSerialRequest(mes).subscribe( done => {
      console.log(done);
    })
  }
  editConfig(terminal: KDSTerminal) {
    this.kds.setActiveTerminal(terminal.id);
    this.router.navigateByUrl('/kds/config/' + terminal.id);
  }
  ngOnInit(): void {
    this.loadAccounts();    

    this.filterAccountControl.valueChanges.subscribe( (d) => {       
      if (typeof d == 'object') {
        this.activeAccount = d.id;
        this.loadTerminalsForUserID(this.activeAccount);
        this.filterAccountControl.setValue(d.name);        
      } else if (typeof d == 'string'){
        this.filteredaccountsData = (this._filterAccounts(d));
      }
    });
  }

}
