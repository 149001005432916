import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-kds-user-types',
  templateUrl: './kds-user-types.component.html',
  styleUrls: ['./kds-user-types.component.css']
})
export class KdsUserTypesComponent implements OnInit {

  constructor() { }
 
  ngOnInit(): void {
  }

}
