<app-generic-loading *ngIf="showLoading"></app-generic-loading>
<div *ngIf="!showLoading">
  <app-kds-partial-header></app-kds-partial-header>

  <div style="padding:10px;">
    <mat-card class="example-card">
      <mat-card-header>
        <mat-card-title>Accounts</mat-card-title>
        <mat-card-subtitle>Account admin (accounts are essentially business locations)</mat-card-subtitle>
      </mat-card-header>
      <mat-card-actions>
        <button (click)="addAccount()" mat-mini-fab>+</button>
      </mat-card-actions>
    </mat-card>

    <br />

    <div *ngIf="!(this.accountsData.length > 0)">
      <h1>No Accounts Yet</h1>
    </div>

    <div *ngIf="this.datasource.length > 0">

      <mat-form-field appearance="standard">
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Filter Table" #input>
      </mat-form-field>
      <table mat-table [dataSource]="datasource" class="mat-elevation-z8">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> Name </th>
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>      
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef> Actions </th>
          <td mat-cell *matCellDef="let element">          
            <button (click)="editAccount(element)" mat-raised-button color="accent">EDIT</button> |
            <button (click)="deleteAccount(element.id)" mat-raised-button color="accent">DEFAULT CONFIG (V2)</button> | 
            <button (click)="deleteAccount(element.id)" mat-raised-button color="warn">DELETE</button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

    </div>
  </div>
</div>
