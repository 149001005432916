import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { APPMESSAGES } from 'src/app/datatypes/generic/messages';
import { KDSAccount } from 'src/app/datatypes/kds/kdsaccount';
import { KDSTerminal } from 'src/app/datatypes/kds/kdsterminal';
import { HttpService } from 'src/app/services/http.service';
import { KDSQuery } from 'src/app/services/kds.query';

@Component({
  selector: 'app-dialog-terminal-add',
  templateUrl: './dialog-terminal-add.component.html',
  styleUrls: ['./dialog-terminal-add.component.css']
})
export class DialogTerminalAddComponent implements OnInit {

  selectedValue: any;
  searchTxt: any;
  
  newterminalForm = new UntypedFormGroup({
    name: new UntypedFormControl('', Validators.required),
    account_id: new UntypedFormControl('', [Validators.required]),
    id: new UntypedFormControl(0),
    hwid: new UntypedFormControl(''),
    connection_code: new UntypedFormControl(''),
  });
  accountsData = new Array<KDSAccount>();

  changed(event) {
    console.log(event.value);
  }

  addTerminal() {
    let kdsterm = new KDSTerminal();
    kdsterm = this.newterminalForm.value as KDSTerminal
    kdsterm.account_id = +(this.newterminalForm.get('account_id').value as number);
    if (kdsterm.connection_code.length < 4) {
      kdsterm.connection_code = this.kds.getValue().user.id + "-" + kdsterm.account_id + "-" + this.makeid(10);
    }
    console.log(kdsterm);
    this.http.KDSCreateTerminal(kdsterm).subscribe((httpdata) => {
      this.dialogRef.close(APPMESSAGES.KDS_ENTRY_ADDED);
    });
  }
  loadAccounts() {
    this.kds.getActiveUser().subscribe((user) => {
      if (user.id > 0) {
        this.kds.setActiveUser(user);
        console.log(this.kds.getUserAccounts());
        if (this.kds.getUserAccounts().length < 1) {
          this.http.KDSGetAccounts(user.id).subscribe((httpdata) => {
            this.accountsData = httpdata;
            this.kds.setActiveAccounts(this.accountsData);
          });
        } else {
          this.accountsData = this.kds.getUserAccounts();
        }
      } else {
        this.router.navigateByUrl('/kds/login');
      }
    });
  }
  makeid(length: number) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  constructor(private kds: KDSQuery, private router: Router, private http: HttpService,
    public dialogRef: MatDialogRef<DialogTerminalAddComponent>, @Inject(MAT_DIALOG_DATA) public data: { terminal: KDSTerminal }) { }

  ngOnInit(): void {
    this.loadAccounts();
    if (this.data !== null) {
      this.newterminalForm.patchValue(this.data.terminal);
      this.newterminalForm.controls['account_id'].setValue('' + this.data.terminal.account_id, { onlySelf: true });
    }
  }

}
