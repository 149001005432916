import { Store, StoreConfig } from '@datorama/akita';
import { KDSAccount } from '../datatypes/kds/kdsaccount';
import { KDSPermission } from '../datatypes/kds/kdspermissions';
import { KDSTerminal } from '../datatypes/kds/kdsterminal';
import { KDSUser } from '../datatypes/kds/kdsuser';
import { KDSUserType } from '../datatypes/kds/kdsusertypes';

export interface KDSState {
    user: KDSUser;
    accounts: KDSAccount[],
    terminals: KDSTerminal[],    
    activeTerminal: number,
    permissions: KDSPermission[],
    userTypes: KDSUserType[]
}

export function createInitialState(): KDSState {
    return {
        user: new KDSUser(),
        accounts: new Array<KDSAccount>(),
        terminals: new Array<KDSTerminal>(),
        activeTerminal: 0 , 
        permissions: new Array<KDSPermission>(),
        userTypes: new Array<KDSUserType>(),
    }
}

@StoreConfig({ name: 'kds' })
export class KDSStore extends Store<KDSState> {
    constructor() {
        super(createInitialState());
    }
}