
import { KDSAccount, KDSUserAccounts, KDSUserAccountsDB } from 'src/app/datatypes/kds/kdsaccount';
import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { KDSQuery } from 'src/app/services/kds.query';
import { HttpService } from 'src/app/services/http.service';
import { KDSUser } from 'src/app/datatypes/kds/kdsuser';
import { count } from 'rxjs/operators';
import { arrayAdd } from '@datorama/akita';

@Component({
  selector: 'app-dialog-user-permissions-manage',
  templateUrl: './dialog-user-permissions-manage.component.html',
  styleUrls: ['./dialog-user-permissions-manage.component.css']
})
export class DialogUserPermissionsManageComponent implements OnInit {

  constructor(private kds: KDSQuery, private router: Router, private http: HttpService, public dialogRef: MatDialogRef<DialogUserPermissionsManageComponent>, @Inject(MAT_DIALOG_DATA) public data: { user: KDSUser }) { }
  useraccounts = new UntypedFormControl();
  toppingList: number[] = [1,20,30,40,50];
  accountsData = new Array<KDSAccount>();
  accountsFromDB = new Array<KDSUserAccountsDB>();
  
  selectedValue: any;
  searchTxt: any;

  loadAccounts() {
    this.kds.getActiveUser().subscribe((user) => {
      if (user.id > 0) {
        this.kds.setActiveUser(user);        
        if (this.kds.getUserAccounts().length < 1) {
          this.http.KDSGetAccounts(user.id).subscribe((httpdata) => {
            this.accountsData = httpdata;
            this.kds.setActiveAccounts(this.accountsData);
          });
        } else {
          this.accountsData = this.kds.getUserAccounts();
        }
      } else {
        this.router.navigateByUrl('/kds/login');
      }
    });
  }
  updateperms() {
    console.log(this.useraccounts);
    let kdsperm = new KDSUserAccounts();
    kdsperm.user_id = this.data.user.id;
    kdsperm.user_accounts = this.useraccounts.value;
    console.log(kdsperm);
    this.http.KDSUPDATEUserAccountsPermission(kdsperm).subscribe( (res) => {
      console.log(res);
      this.dialogRef.close();
    });
  }

  ngOnInit(): void {
    this.loadAccounts();
    if (this.data !== null && this.data.user.id > 0) {
      this.http.KDSGETUserAccountsPermission(this.data.user.id).subscribe( (res: KDSUserAccountsDB[]) => {      
        if (res.length > 0) {
          let def = new Array<number>();
          res.forEach( entry => {
            def.push(entry.account_id);            
          });          
          this.useraccounts.patchValue(def);
        }
      });
    }
  }

}
