import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { KDSReportOutput } from 'src/app/datatypes/kds/kdsreports';
import { KDSTerminalMessage } from 'src/app/datatypes/kds/kdsterminalmessage';
import { KDSUser } from 'src/app/datatypes/kds/kdsuser';
import { HttpService } from 'src/app/services/http.service';
import { KDSQuery } from 'src/app/services/kds.query';

@Component({
  selector: 'app-kds-reports',
  templateUrl: './kds-reports.component.html',
  styleUrls: ['./kds-reports.component.css']
})
export class KdsReportsComponent implements OnInit {

  public RunReportType = null;
  public RunReportFormat = null;
  public RunReportOutput = null;

  public showLoading = true;
  public reports = [];
  public localUser : KDSUser;
  public reportType = "pdf";
  public accountsData = [];
  public terminalsData = [];
  public userReports = [];
  public ListReports = [
    { 
      name : "Summary Report" , 
      value : "SUMMARY"
    },
    { 
      name : "Detailed Report" , 
      value : "DETAILED"
    },
    { 
      name : "Detailed Report With Items" , 
      value : "DETAILEDITEMS"
    },
    { 
      name : "Detailed Waiter Report" , 
      value : "WAITERSUMMARY"
    },
    
  ];
  public ListReportFormats = ["PDF" , "XLSX"];
  public ListReportOutouts = ["DOWNLOAD" , "EMAIL"]

  range = new UntypedFormGroup({
    start: new UntypedFormControl('',Validators.required),
    end: new UntypedFormControl('',Validators.required),
    reporttype: new UntypedFormControl('',Validators.required),
    reportformat: new UntypedFormControl('',Validators.required),
    reportoutput: new UntypedFormControl('',Validators.required),
    email: new UntypedFormControl(''),
  });

  constructor(private kds: KDSQuery, private http: HttpService, private router: Router) { }

  switchReport(t : string): void {
    this.reportType = t;
  }
  getStart() {
    return this.range.get('start').value;
  }
  loadTerminalsForUserID(id){
    this.http.KDSGetTerminals(id).subscribe( (data) => {      
      this.terminalsData = data;
    });
  }
  onReport() {
    if (this.range.valid){      
      const unixTimeZeroStart = Date.parse(this.range.get('start').value);
      const unixTimeZeroEnd = Date.parse(this.range.get('end').value);
      let kdsinput = new KDSReportOutput();
      kdsinput.from_date = new Date(unixTimeZeroStart).toLocaleString('en-ZA', {timeZone: 'Africa/Harare'}).replace(/,/g, '');
      kdsinput.to_date = new Date(unixTimeZeroEnd).toLocaleString('en-ZA', {timeZone: 'Africa/Harare'}).replace(/,/g, '');
      kdsinput.report_type = this.range.get('reporttype').value;
      kdsinput.user_id = this.localUser.id;
      kdsinput.report_format = this.range.get('reportformat').value;
      kdsinput.report_output = this.range.get('reportoutput').value;     
      kdsinput.report_email = this.range.get('email').value;  
      let mes = new KDSTerminalMessage();
      mes.message = JSON.stringify(kdsinput);
      mes.terminal_id = this.kds.getActiveTerminal();
      mes.type = "RUN_REPORT_NEW";
      this.http.KDSCreateSystemMessage(mes).subscribe( done => {
        console.log(done);   
        if (kdsinput.report_output == "DOWNLOAD"){
          alert('Your report will be run by the server , please click refresh below in a few minutes to see if your report is done if you chose to download it.');
        } else {
          alert('Keep an eye on email for the report.');
        }     
      })      
    }        
  }
  loadAccounts(){
    this.kds.getActiveUser().subscribe( (user) => {
      if (undefined !== user && user.id > 0) {
        this.kds.setActiveUser(user);  
        this.http.KDSGetReportAccounts(user.id).subscribe( (httpdata) => {            
          this.accountsData = httpdata;
          this.showLoading = false;          
        }); 
      } else {
        this.router.navigateByUrl('/kds/login');
      }      
    });
  }
  ReportTypeDropChanged(event) {
    this.RunReportType = event.value;    
    console.log(this.range);
  }
  ReportFormatDropChanged(event) {
    this.RunReportFormat = event.value;    
  }
  ReportOutputDropChanged(event) {
    this.RunReportOutput = event.value;    
  }
  AccountDropChanged(event) {    
    //this.activeAccount = event.value;
    this.loadTerminalsForUserID(event.value);
  }
  TermianlDropChanged(event) {    
    //this.activeAccount = event.value;
    //this.loadTerminalsForUserID(event.value);
  }
  refreshReports(){
    this.http.KDSGetUserReports(this.localUser.id).subscribe( reports => {
      this.userReports = reports;      
    })
  }
  deletereport(event) {
    console.log(event);
    this.http.KDSDeleteUserReport(this.localUser.id , event as number).subscribe( reports => {
      this.userReports = reports;      
    })
  }

  ngOnInit(): void {
    this.showLoading = false;
    this.kds.getActiveUser().subscribe( (user) => {
      this.localUser = user;
      this.range.get("email").patchValue(this.localUser.email);
      this.loadAccounts();
      this.http.KDSGetUserReports(user.id).subscribe( reports => {
        this.userReports = reports;
        //console.log(this.userReports);
      })
    });
  }

}
